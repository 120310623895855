import { create } from 'zustand';

interface AISidebarStore {
  isOpen: boolean;
  page?: 'checklists';
  data?: any;
}

interface AISidebarState {
  aiSidebarStore: AISidebarStore;
  setIsAISidebarOpen: (aiSidebarStore: boolean | AISidebarStore) => void;
}

const useAISidebar = create<AISidebarState>((set) => ({
  aiSidebarStore: {
    isOpen: false,
  },
  setIsAISidebarOpen: (aiSidebarStore: boolean | AISidebarStore) => {
    if (typeof aiSidebarStore === 'boolean') return set((_state) => ({ aiSidebarStore: { isOpen: aiSidebarStore } }));
    return set((_state) => ({ aiSidebarStore }));
  },
}));

export default useAISidebar;
