import api from '../../../config/api/api.config';

const addFormApi = {
  addForm: (payload: any) =>
    api.post(`checklist_template`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
};

export default addFormApi;
