import React, { ReactElement, useEffect, useState } from 'react';
import { VideoCameraIcon, CameraIcon, Bars3CenterLeftIcon, Bars4Icon } from '@heroicons/react/24/outline';
import { Button } from 'antd';
import { cn } from 'modules/common/services/utils.service';
import { TextCheck, PhotoCheck, MultipleChoiceCheck } from './components';
import { CheckArrayContent } from '../../form-details.type';

const checkList = [
  {
    name: 'Open Text',
    icon: <Bars3CenterLeftIcon height={24} width={24} className="m-auto text-white" />,
    type: 'text',
    selected: false,
    disabled: false,
  },
  {
    name: 'Multiple Choice',
    icon: <Bars4Icon height={24} width={24} className="m-auto text-white" />,
    type: 'multiple_choice',
    selected: false,
    disabled: false,
  },
  {
    name: 'Photo',
    icon: <CameraIcon height={24} width={24} className="m-auto text-white" />,
    type: 'image',
    selected: false,
    disabled: false,
  },
  {
    name: 'Photo + Text',
    icon: (
      <div>
        <CameraIcon height={24} width={24} className="m-auto text-white inline-block" />
        <Bars3CenterLeftIcon height={24} width={24} className="m-auto text-white inline-block" />
      </div>
    ),
    type: 'text-image',
    selected: false,
    disabled: false,
  },
  {
    name: 'Video',
    icon: <VideoCameraIcon height={24} width={24} className="m-auto text-white" />,
    type: 'video',
    selected: false,
    disabled: true,
  },
  {
    name: 'Video + Text',
    icon: (
      <div>
        <VideoCameraIcon height={24} width={24} className="m-auto text-white inline-block" />
        <Bars3CenterLeftIcon height={24} width={24} className="m-auto text-white inline-block" />
      </div>
    ),
    type: 'text-video',
    selected: false,
    disabled: true,
  },
];

const answerTypeList = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Date Range',
    value: 'date-range',
  },
  {
    label: 'Single Selection',
    value: 'single-selection',
  },
  {
    label: 'Multiple Selection',
    value: 'multiple-selection',
  },
  {
    label: 'Single Image Open',
    value: 'single-image-open',
  },
  {
    label: 'Multiple Image Open',
    value: 'multiple-image-open',
  },
  {
    label: 'Single Video Open',
    value: 'single-video-open',
  },
  {
    label: 'Multiple Video Open',
    value: 'multiple-video-open',
  },
];

type CheckProps = {
  name: string;
  icon: ReactElement;
  type: string;
  selected: boolean;
  disabled: boolean;
};

type SelectChecklistProps = {
  setCheckArray: (checkArray: CheckArrayContent[]) => void;
  checkArray: CheckArrayContent[];
  setShowCheckTypes: (showCheckType: boolean) => void;
  setEditCheckIndex: (editCheckIndex: number | undefined) => void;
  editCheckIndex: number | undefined;
};

const SelectChecklist: React.FC<SelectChecklistProps> = ({
  setCheckArray,
  checkArray,
  setShowCheckTypes,
  editCheckIndex,
  setEditCheckIndex,
}) => {
  const [selectedCheck, setSelectedCheck] = useState<string>('');
  const [checkTypes, setCheckTypes] = useState<CheckProps[]>(checkList);

  useEffect(() => {
    if (editCheckIndex !== undefined) {
      setSelectedCheck(
        checkArray[editCheckIndex].multiple_choice_p === 1 ? 'multiple_choice' : checkArray[editCheckIndex].check_type,
      );
    }
  }, [editCheckIndex]);

  const checkClicked = (index: number) => {
    setCheckTypes((prev) =>
      prev.map((check, i) => {
        let updatedSelected = false;
        if (index === i) {
          updatedSelected = true;
        }
        return { ...check, selected: updatedSelected };
      }),
    );
  };
  const cancelPressed = () => {
    setCheckTypes((prev) =>
      prev.map((check) => ({
        ...check,
        selected: false,
      })),
    );
    setSelectedCheck('');
    setEditCheckIndex(undefined);
  };

  const nextPressed = () => {
    const selected = checkTypes.filter((check) => check.selected);
    if (selected.length > 0) setSelectedCheck(selected[0].type);
  };

  const getSelectedCheck = () => {
    switch (selectedCheck) {
      case 'text':
        return (
          <TextCheck
            clearSelected={cancelPressed}
            setCheckArray={setCheckArray}
            checkArray={checkArray}
            answerTypeList={answerTypeList}
            editCheckIndex={editCheckIndex}
            check_type={selectedCheck}
          />
        );

      case 'image':
      case 'text-image':
        return (
          <PhotoCheck
            clearSelected={cancelPressed}
            setCheckArray={setCheckArray}
            checkArray={checkArray}
            answerTypeList={answerTypeList}
            editCheckIndex={editCheckIndex}
            check_type={selectedCheck}
          />
        );
      case 'multiple_choice':
        return (
          <MultipleChoiceCheck
            clearSelected={cancelPressed}
            setCheckArray={setCheckArray}
            checkArray={checkArray}
            answerTypeList={answerTypeList}
            editCheckIndex={editCheckIndex}
            check_type={selectedCheck}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <div>
      {selectedCheck || editCheckIndex !== undefined ? (
        getSelectedCheck()
      ) : (
        <>
          <div className="px-4 pt-4 pb-12 bg-white rounded">
            <h1 className="font-bold">Select Type of Form</h1>
            <div className="grid grid-cols-3 mt-10">
              {checkTypes.map((check, index) => (
                <button
                  type="button"
                  key={check.name}
                  disabled={check.disabled}
                  className={cn('m-2 py-6 rounded-3xl font-bold bg-[#CCCCCC]', {
                    'bg-tableHeaderColor text-white': check.selected,
                    'opacity-60': check.disabled,
                    'hover:bg-tableHeaderColor hover:text-white': !check.disabled,
                  })}
                  onClick={() => checkClicked(index)}
                >
                  {check.icon}
                  {check.name}
                </button>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 max-w-4xl w-full mt-4">
            <div className="text-left pl-6">
              <Button type="text" className="!font-bold" onClick={() => setShowCheckTypes(false)}>
                Previous
              </Button>
            </div>
            {checkTypes.filter((check) => check.selected).length > 0 && (
              <div className="flex justify-end">
                <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={cancelPressed}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
                  onClick={nextPressed}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectChecklist;
