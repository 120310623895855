import api from '../../../../../config/api/api.config';

const checklistInfoApi = {
  getChecklistPoints: () => api.get(`checklist_points`),
  getChecklistTypes: () => api.get(`checklist_types`),
  getUsersList: () => api.get(`users`),
  getRoles: () => api.get('roles'),
};

export default checklistInfoApi;
