import { twMerge } from 'tailwind-merge';
import { clsx, ClassValue } from 'clsx';

const utils = {
  // eslint-disable-next-line no-promise-executor-return
  sleep: (interval: number) => new Promise((resolve) => setTimeout(resolve, interval)),
  getViewPort: () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return { vw, vh };
  },
};

export const capitalize = (s: string) =>
  s
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export default utils;
