import axios, { AxiosResponse } from 'axios';
import session, { SessionData } from 'config/session/session.config';
import { serverUrl } from 'config/variables/system.variable';
// eslint-disable-next-line import/no-cycle

const BaseUrl = serverUrl;

let apiOptions: any = {
  headers: {},
};

function updateOptions(options: any) {
  return {
    ...apiOptions,
    ...options,
    headers: {
      ts: new Date().toISOString(),
      ...apiOptions.headers,
      ...options.headers,
    },
  };
}

function getApiUrl(url: string, apiUrl: string | boolean = false) {
  if (apiUrl) return `${apiUrl}/${url}`;
  return `${BaseUrl}/${url}`;
}

function errorHandler(error: { response: AxiosResponse }) {
  if (error.response?.status === 401) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    api.removeAuthenticationHeader();
    session.destroySession();
    window.location.href = '/';
  }
  if (error.response) return error.response;
  return error;
}

const api = {
  get: (url: string, options: any = {}, apiUrl: string | boolean = false) =>
    axios.get(getApiUrl(url, apiUrl), updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  post: (url: string, payload: unknown = {}, options: any = {}, apiUrl: string | boolean = false) =>
    axios.post(getApiUrl(url, apiUrl), payload, updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  put: (url: string, payload = {}, options: any = {}, apiUrl: string | boolean = false) =>
    axios.put(getApiUrl(url, apiUrl), payload, updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  delete: (url: string, options: any = {}, apiUrl: string | boolean = false) =>
    axios.delete(getApiUrl(url, apiUrl), updateOptions(options)).catch(errorHandler) as Promise<AxiosResponse>,
  setAuthenticationHeader: (tokenData: SessionData) => {
    apiOptions = {
      headers: {
        'x-access-token': tokenData.token,
        'x-user-id': tokenData.id,
        'x-username': tokenData.username,
      },
    };
  },
  removeAuthenticationHeader: () => {
    apiOptions = {};
  },
};

export type TApiResponse<T = undefined> = Promise<
  AxiosResponse<{
    data: T;
    action: string;
    status: 'successful' | 'failed';
    message?: Partial<string>;
  }>
>;

export type TApiCustomResponse<T = undefined> = Promise<AxiosResponse<T>>;

export default api;
