import { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import submissionListingApi from './submission-listing.api';
import { Loader } from '../../common/components';
import SubmissionListingTable from './components/listing-table/SubmissionListingTable.component';
import { SubmissionFilters } from './components';

type TFilters = {
  from_timestamp: string | undefined;
  to_timestamp: string | undefined;
};

const ChecklistListing = () => {
  const [checklists, setChecklists] = useState([]);
  const gridRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const checklistTemplateId = Number(searchParams.get('id'));
  const [filters, setFilters] = useState<TFilters>({
    from_timestamp: checklistTemplateId ? undefined : moment().startOf('month').format('YYYY-MM-DD [00:00:00]'),
    to_timestamp: checklistTemplateId ? undefined : moment().format('YYYY-MM-DD [23:59:59]'),
  });

  const getChecklists = useQuery(
    'Get Checklists',
    async () => submissionListingApi.getChecklists(filters, checklistTemplateId),
    {
      enabled: false,
      onSuccess: (response) => {
        const { data, status } = response;
        if (status === 200 && data.status === 'successful') {
          setChecklists(data.submitted_checklists);
        }
      },
    },
  );

  useEffect(() => {
    getChecklists.refetch();
  }, [filters]);

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>Submissions</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {getChecklists.isFetching && <Loader height="50px" width="50px" color="black" showTitle />}
      <SubmissionFilters filters={filters} setFilters={setFilters} />

      {getChecklists.isFetched && <SubmissionListingTable gridRef={gridRef} rowData={checklists} />}
    </div>
  );
};

export default ChecklistListing;
