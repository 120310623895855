/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import './checklist-info.css';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import checklistInfoApi from './checklistInfo.api';

export type ChecklistDetailData = {
  checklist_id?: number;
  checklist_name: string;
  checklist_desc: string;
  checklist_status: boolean | undefined;
  checklist_type_id?: number;
  checklist_point_id?: number[];
};

type ChecklistInfoProps = {
  checklistDetails: ChecklistDetailData | undefined;
  setChecklistDetails: (checklistDetail: ChecklistDetailData) => void;
  setShowCheckTypes: (showCheckType: boolean) => void;
  deleteChecklist?: () => void;
};

const ChecklistInfo: React.FC<ChecklistInfoProps> = ({
  checklistDetails,
  setChecklistDetails,
  setShowCheckTypes,
  deleteChecklist,
}) => {
  const [checklistPoints, setChecklistPoints] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const getChecklistPoints = useQuery('Get Checklist Points', async () => checklistInfoApi.getChecklistPoints(), {
    enabled: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setChecklistPoints(data.checklist_points);
      }
    },
  });

  const handleFinish = (values: ChecklistDetailData) => {
    setChecklistDetails({ ...values, checklist_id: checklistDetails?.checklist_id });
    setShowCheckTypes(true);
  };

  const confirmDeleteChecklist = () => {
    Modal.confirm({
      title: 'Delete Checklist',
      className: 'checklist-confirm-modal',
      content: 'Are you sure you want to delete this checklist?',
      centered: true,
      cancelButtonProps: {
        className: 'checklist-confirm-modal-cancel-button',
      },
      okButtonProps: { className: 'checklist-confirm-modal-ok-button' },
      width: 480,
      onOk() {
        if (deleteChecklist) deleteChecklist();
      },
    });
  };

  useEffect(() => {
    getChecklistPoints.refetch();
  }, []);

  useEffect(() => {
    if (checklistDetails) {
      form.setFieldValue('checklist_name', checklistDetails.checklist_name);
      form.setFieldValue('checklist_desc', checklistDetails.checklist_desc);
      form.setFieldValue('checklist_point_id', checklistDetails.checklist_point_id);
      form.setFieldValue('checklist_status', checklistDetails.checklist_status);
    }
  }, [checklistDetails]);

  return (
    <div>
      <div className="flex justify-center flex-col items-center mt-10">
        <div className="bg-white p-10 max-w-4xl w-full rounded">
          <Form
            labelCol={{ span: 6, style: { textAlign: 'left' } }}
            wrapperCol={{ span: 14 }}
            form={form}
            onFinish={handleFinish}
          >
            <Form.Item
              label="Checklist Name"
              name="checklist_name"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter checklist name!' }]}
            >
              <Input className="font-normal" />
            </Form.Item>
            <Form.Item label="Checklist Description" name="checklist_desc" className="font-bold">
              <Input.TextArea rows={4} className="font-normal" />
            </Form.Item>
            <Form.Item label="Checklist Point" name="checklist_point_id" className="font-bold">
              <Select mode="multiple" allowClear className="capitalize">
                {checklistPoints.map((point: { checklist_point_id: number; checklist_point_name: string }) => (
                  <Select.Option key={point.checklist_point_id} value={point.checklist_point_id} className="capitalize">
                    {point.checklist_point_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="checklist_status"
              className="font-bold"
              valuePropName="checked"
              initialValue
            >
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
            </Form.Item>
          </Form>
        </div>
        <div className="grid grid-cols-2 max-w-4xl w-full mt-4">
          <div className="text-left pl-6">
            {checklistDetails ? (
              <Button type="text" className="!font-bold" onClick={confirmDeleteChecklist}>
                Delete
              </Button>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={() => navigate(`/checklists`)}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
              onClick={() => form.submit()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistInfo;
