import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import useCurrentUser from 'modules/common/hooks/use-current-user/useCurrentUser.hook';
import { useAISidebar } from 'modules/common/hooks';
import { capitalize, cn } from 'modules/common/services/utils.service';
import mockData from './mock-data.json';

type TAIAction = {
  type: string;
  details: { action: string; description: string }[];
  showDetails?: boolean;
};

type TReportAction = {
  title: string;
  description: string;
  actions: TAIAction[];
};

const AISidebar = () => {
  const { aiSidebarStore, setIsAISidebarOpen } = useAISidebar();
  const { currentUser } = useCurrentUser();
  const [reportActions, setReportActions] = useState<TReportAction>();

  useEffect(() => {
    if (!aiSidebarStore.page) return;
    setReportActions(mockData[aiSidebarStore.page] || undefined);
  }, [aiSidebarStore.page]);

  return (
    <div
      className={cn(
        'relative h-screen overflow-y-auto bg-white p-0.5 shadow-md transition-all duration-1000 ease-in-out',
        {
          'w-0 opacity-0': !aiSidebarStore.isOpen,
          'w-[500px] opacity-100': aiSidebarStore.isOpen,
        },
      )}
    >
      {aiSidebarStore.isOpen ? (
        <div className="px-2">
          <div className="fixed top-0 flex w-[400px] items-center justify-between">
            <img src="/iblinkx-ai.gif" alt="logo" className="h-12" />
            <button
              type="button"
              onClick={() => setIsAISidebarOpen(false)}
              className="absolute right-12 top-0 p-2 text-primary"
            >
              <XMarkIcon width={20} className="text-black" />
            </button>
          </div>
          <div className="mt-12">
            <h1 className="text-3xl font-bold text-primary">
              Hello, {capitalize(currentUser?.user_details.first_names_en || '')}
            </h1>
          </div>

          {reportActions ? (
            <div>
              <h2 className="mt-4 text-2xl font-bold">{reportActions.title}</h2>
              <h3 className="mt-2">{reportActions.description}</h3>
              <div className="mt-2 flex flex-col gap-2">
                {reportActions.actions.map((action) => (
                  <button
                    key={action.type}
                    type="button"
                    className="w-full rounded-md bg-gray-100 p-2 text-left hover:bg-gray-200"
                    // onClick={() => {
                    //   // eslint-disable-next-line no-param-reassign
                    //   action.showDetails = !action.showDetails;
                    //   setReportActions({ ...reportActions });
                    // }}
                  >
                    <div className="flex justify-between items-center">
                      <span className="font-semibold text-primary">{action.type}</span>
                      <ArrowRightIcon width={20} />
                    </div>
                    {action.showDetails ? (
                      <div className="mt-2 space-y-2">
                        {action.details.map((detail) => (
                          <div key={detail.action} className="flex flex-col gap-2">
                            <div className="font-semibold">{detail.action}</div>
                            <div>{detail.description}</div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default AISidebar;
