import api from '../../../config/api/api.config';

const fillChecklistApi = {
  getChecklistDetail: (userEmail: string, userToken: string) =>
    api.get('get_open_checklist', { params: { email: userEmail, token: userToken } }),
  submitChecklist: (payload: any) =>
    api.post(`submit_open_checklist`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
};

export default fillChecklistApi;
