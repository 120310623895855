import api, { TApiResponse } from '../../../config/api/api.config';

const editFormApi = {
  getChecklistDetail: (checklistId: string) => api.get(`checklist_template/${checklistId}`),
  updateChecklist: (checklistId: string, payload: any) =>
    api.put(`checklist_template/${checklistId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteChecklist: (checklistTemplateId): TApiResponse => api.delete(`checklist_template/${checklistTemplateId}`),
};

export default editFormApi;
