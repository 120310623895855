/* eslint-disable react/no-array-index-key */
import { Button, Form, Input, message, Select, Switch, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusCircleIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { v4 as uuidv4 } from 'uuid';
import { CheckProps } from '../text-check/TextCheck.component';
import { getBase64 } from '../photo-check/PhotoCheck.component';
import { ChoiceArray } from '../../../../checklist-details.type';

const choiceTypes = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Image',
    value: 'image',
  },
];

const initialChoices = [
  {
    choice_text: '',
    choice_type: 'text',
    filename: '',
  },
  {
    choice_text: '',
    choice_type: 'text',
    filename: '',
  },
  {
    choice_text: '',
    choice_type: 'text',
    filename: '',
  },
];

const MultipleChoiceCheck: React.FC<CheckProps> = ({
  clearSelected,
  setCheckArray,
  checkArray,
  editCheckIndex,
  answerTypeList,
}) => {
  const [choiceList, setChoiceList] = useState<ChoiceArray[]>(initialChoices);
  const [choiceType, setChoiceType] = useState<string>('text');
  const [choiceToBeDeleted, setChoiceToBeDeleted] = useState<number[]>([]);
  const [editStatus, setEditStatus] = useState<string>('inserted');
  const [form] = Form.useForm();

  useEffect(() => {
    if (editCheckIndex !== undefined) {
      const selectedCheck = checkArray[editCheckIndex];
      setEditStatus(selectedCheck.check_id ? 'updated' : 'inserted');
      form.setFieldValue('check_text', selectedCheck.check_text);
      form.setFieldValue('checklist_status', selectedCheck.status);
      form.setFieldValue('required', selectedCheck.required_p === 1);
      form.setFieldValue('answer_type', selectedCheck.answer_type);
      if (selectedCheck.choices && selectedCheck.choices.length > 0) {
        if (selectedCheck.choices[0].choice_type === 'image') {
          setChoiceType('image');
          form.setFieldValue('choice_type', 'image');
        } else {
          setChoiceType('text');
          form.setFieldValue('choice_type', 'text');
        }
      }
      if (selectedCheck.choices) {
        setChoiceList(selectedCheck.choices);
        selectedCheck.choices.forEach((choice, index) => {
          form.setFieldValue(`choice_${index}`, choice.choice_type === 'image' ? choice.media_url : choice.choice_text);
        });
      }
    }
  }, [editCheckIndex]);

  const handleFinish = (values: any) => {
    const newCheck = {
      check_type: 'text',
      check_text: values.check_text,
      check_name: values.check_text,
      edit_status: editStatus as any,
      status: !!values.checklist_status,
      answer_type: values.answer_type,
      multiple_choice_p: 1,
      choices: choiceList,
      choice_type: choiceType,
      choice_to_be_deleted: choiceToBeDeleted,
      required_p: values.required === true ? 1 : 0,
    };

    if (newCheck.choices.length < 2) {
      message.error('Please add at least 2 choices');
      return;
    }

    if (editCheckIndex !== undefined) {
      const currentCheck = checkArray[editCheckIndex];
      setCheckArray(
        checkArray.map((check, index) =>
          index === editCheckIndex
            ? { ...newCheck, check_id: currentCheck.check_id, check_temp_id: currentCheck.check_temp_id }
            : check,
        ),
      );
    } else {
      setCheckArray([...checkArray, { ...newCheck, check_temp_id: uuidv4() }]);
    }
    clearSelected();
  };
  const addChoiceClicked = () => {
    setChoiceList((prev) => [...prev, { choice_text: '', choice_type: choiceType, filename: '' }]);
  };
  const onChoiceTypeChange = (value: string) => {
    setChoiceType(value);
    setChoiceList((prev) => prev.map((choice) => ({ ...choice, choice_type: value })));
  };
  const removeChoice = (index: number) => {
    const choice = choiceList[index];
    if (choice.choice_id) {
      if (!choiceToBeDeleted.includes(choice.choice_id)) {
        setChoiceToBeDeleted([...choiceToBeDeleted, choice.choice_id]);
      }
    }
    setChoiceList((prev) => prev.filter((_item, i) => i !== index));
  };
  const onChoiceChange = (value: string, index: number) => {
    setChoiceList((prev) => prev.map((choice, i) => (i === index ? { ...choice, choice_text: value } : choice)));
  };
  const uploadButton = (
    <div>
      <PlusIcon height={20} width={20} className="m-auto" />
      <div style={{ marginTop: 8 }}>Select Image</div>
    </div>
  );
  const handleChange = (index: number) => (info: UploadChangeParam<UploadFile>) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    if (fileList.length > 0) {
      const file = fileList[0];
      const blob = file.originFileObj?.slice(0, file.size, file.type) || '';
      const fileExtension = file.name.split('.').pop();
      const fileName = `img_${new Date().getTime()}.${fileExtension}`;
      const updatedFile = new File([blob], fileName, { type: 'image/png' });
      const isLt2M = (updatedFile?.size || 0) / 1024 / 1024 < 2;
      if (isLt2M) {
        getBase64(updatedFile as RcFile, (url) => {
          // setLoading(false);
          // setImageUrl(url);
          setChoiceList((prev) =>
            prev.map((choice, i) =>
              i === index ? { ...choice, file: updatedFile, filename: fileName, media_url: url } : choice,
            ),
          );
        });
      } else {
        message.error('Image must smaller than 2MB!');
      }
    }
  };
  return (
    <>
      <div className="bg-white p-10 max-w-4xl w-full rounded">
        <Form
          labelCol={{ span: 6, style: { textAlign: 'left' } }}
          wrapperCol={{ span: 14 }}
          form={form}
          onFinish={handleFinish}
        >
          <Form.Item
            label="Question"
            name="check_text"
            className="font-bold"
            rules={[{ required: true, message: 'Please enter text!' }]}
          >
            <Input className="font-normal" />
          </Form.Item>
          <Form.Item label="Choice Type" name="choice_type" className="font-bold" required>
            <Select onChange={onChoiceTypeChange} defaultValue="text">
              {choiceTypes.map((choice) => (
                <Select.Option key={choice.value} value={choice.value}>
                  {choice.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {choiceList.map((choice, index) => (
            <Form.Item
              key={index}
              label={`Choice ${index + 1}`}
              name={`choice_${index}`}
              className="font-bold"
              rules={[{ required: true, message: 'Choice is required' }]}
            >
              <div>
                {choiceType === 'text' ? (
                  <Input
                    className="font-normal"
                    value={choice.choice_text}
                    onChange={(e) => onChoiceChange(e.target.value, index)}
                  />
                ) : (
                  <Upload
                    name="image"
                    accept="image/png, image/jpeg"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={() => {
                      return false;
                    }}
                    onChange={handleChange(index)}
                  >
                    {choice.media_url ? (
                      <img src={choice.media_url} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                )}
                <Button
                  type="text"
                  icon={<TrashIcon width={18} height={18} className="text-red-400" />}
                  className="!absolute right-0"
                  onClick={() => removeChoice(index)}
                />
              </div>
            </Form.Item>
          ))}
          <div className="grid grid-cols-9 -mt-5 mb-2 mr-2">
            <div className="col-span-6" />
            <Button
              type="text"
              icon={<PlusCircleIcon height={24} width={24} className="inline-block mr-1 -mt-1" />}
              onClick={addChoiceClicked}
              className="col-span-2"
            >
              Add Choice
            </Button>
          </div>
          <Form.Item
            label="Answer Type"
            name="answer_type"
            className="font-bold"
            rules={[{ required: true, message: 'Please select answer type!' }]}
          >
            <Select>
              {answerTypeList
                .filter((answerType) => answerType.value.includes('selection'))
                .map((answerType) => (
                  <Select.Option key={answerType.value} value={answerType.value}>
                    {answerType.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Required" name="required" className="font-bold" valuePropName="checked" initialValue>
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
          </Form.Item>
          <Form.Item label="Status" name="checklist_status" className="font-bold" initialValue>
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
          </Form.Item>
        </Form>
      </div>
      <div className="max-w-4xl w-full mt-4 mb-4">
        <div className="flex justify-end">
          <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={clearSelected}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
            onClick={() => form.submit()}
          >
            {editCheckIndex !== undefined ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MultipleChoiceCheck;
