import api from '../../../../../config/api/api.config';

const createScheduleFormApi = {
  getChecklists: () => api.get(`checklist_templates?checklist_template_type_id=1`),
  getUsersList: () => api.get(`users`),
  getRoles: () => api.get('roles'),
  getFacilities: () => api.get('facilities'),
  getMeasurements: () => api.get('measurements'),
  getFacilityUsers: (facilityIdList: any) => api.post('facility/users', facilityIdList),
};

export default createScheduleFormApi;
