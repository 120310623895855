import api, { TApiCustomResponse } from '../../../config/api/api.config';

export type TChoicePool = {
  choice_id: number;
  choice_text: string;
  choice_type: string;
  sequence_nr: number;
  answered_count: number;
  percentage?: number;
  name?: string;
  value?: number;
};
export type TCheckPool = {
  check_id: number;
  check_name: string;
  check_text: string;
  check_type: string;
  multiple_choice_p: number;
  sequence_nr: number;
  required_p: number;
  answer_type: string;
  check_status: string;
  media: [];
  choices: TChoicePool[];
  scores: [];
};

type TChecklistPoolResponse = {
  action: 'get_checklist_polling_scores';
  checklist_description: string;
  checklist_name: string;
  checks_score_list: TCheckPool[];
  status: 'successful' | 'failed';
};

const reportDetailApi = {
  getChecklistPool: (checklistId: string): TApiCustomResponse<TChecklistPoolResponse> =>
    api.get(`checklist_polling_scores/${checklistId}`),
};

export default reportDetailApi;
