import React, { useEffect, useState } from 'react';
import { Breadcrumb, message, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import editFormApi from './edit-form.apis';
import { FormDetails, FormInfo } from '../add-form/components';
import { ChecklistDetailData } from '../add-form/components/form-info/FormInfo.component';
import { CheckArray, ChecklistPayload } from '../add-form/add-form.type';
import { CheckArrayContent } from '../add-form/components/form-detail/form-details.type';
import { Loader } from '../../common/components';

const { confirm } = Modal;

const EditForm = () => {
  const navigate = useNavigate();
  const [checklistDetails, setChecklistDetails] = useState<ChecklistDetailData>();
  const [checkArray, setCheckArray] = useState<CheckArrayContent[]>([]);
  const [showCheckTypes, setShowCheckTypes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id = '' } = useParams();

  const getChecklistDetail = useQuery('Get Checklist Detail', async () => editFormApi.getChecklistDetail(id), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200) {
        const { checklist_template: checklist } = data;
        setChecklistDetails({
          checklist_id: checklist.checklist_id,
          checklist_name: checklist.checklist_name,
          checklist_desc: checklist.checklist_description,
          checklist_type_id: checklist.checklist_type_id,
          checklist_point_id: checklist.checklist_point_id,
          checklist_status: checklist.status === 'active',
          all_users_mandatory_p: checklist.all_users_mandatory_p,
          approval_required_p: checklist.approval_required_p,
          user_role_list: checklist.user_role_list,
        });
        setCheckArray(
          checklist.checks.map((check: any) => ({
            check_id: check.check_id,
            check_temp_id: uuidv4(),
            check_name: check.check_name,
            check_text: check.check_text,
            check_type: check.check_type,
            sequence_nr: check.sequence_nr,
            multiple_choice_p: check.multiple_choice_p,
            answer_type: check.answer_type,
            choices: check.choices,
            media: check.media,
            status: check.check_status,
            required_p: check.required_p,
          })),
        );
      }
    },
  });

  const deleteChecklist = async () => {
    const { data, status } = await editFormApi.deleteChecklist(id);
    if (status === 200 && data.status === 'successful') {
      message.success('Form deleted successfully');
      navigate('/forms');
    } else {
      message.error('Something went wrong');
    }
  };

  const updateChecklist = async (formData: any) => {
    setIsLoading(true);
    const { data, status } = await editFormApi.updateChecklist(id, formData);
    setIsLoading(false);
    if (status !== 200 || data.status !== 'successful') {
      message.error('Failed to update form. Please try again');
    } else {
      navigate('/forms');
    }
  };

  const savePressed = () => {
    const formData = new FormData();
    const payload: ChecklistPayload = {
      checklist_id: checklistDetails?.checklist_id,
      checklist_name: checklistDetails?.checklist_name.trim(),
      checklist_description: checklistDetails?.checklist_desc ? checklistDetails?.checklist_desc.trim() : '',
      checklist_type_id: 2,
      checklist_point_id: checklistDetails?.checklist_point_id,
      status: checklistDetails?.checklist_status ? 'active' : 'inactive',
      approval_required_p: (checklistDetails?.approval_required_p || 0) as number,
      all_users_mandatory_p: (checklistDetails?.all_users_mandatory_p || 0) as number,
      user_id_list: checklistDetails?.user_id_list || [],
      role_id_list: checklistDetails?.role_id_list || [],
      checks: checkArray
        .filter((check) => {
          if (!check.check_id && check.edit_status === 'deleted') return false;
          return true;
        })
        .map((check, index) => {
          const checkObj: CheckArray = {
            check_name: check.check_name,
            check_text: check.check_text ? check.check_text : '',
            check_type: check.check_type,
            edit_status: check.edit_status || 'updated',
            multiple_choice_p: check.multiple_choice_p ? 1 : 0,
            status: check.status ? 'active' : 'inactive',
            sequence_nr: index + 1,
            media_to_be_deleted: check?.media_to_be_deleted,
            choice_to_be_deleted: check?.choice_to_be_deleted,
            answer_type: check.answer_type,
            required_p: check.required_p,
          };
          if (check.check_id) {
            checkObj.check_id = check.check_id;
          }
          if (check.filename) {
            checkObj.filename = check.filename;
          }
          if (check.check_type === 'image' || check.check_type === 'text-image') {
            if (check.filename && check.file) {
              check.file.forEach((file) => {
                formData.append(file.name, file);
              });
            }
          }
          if (checkObj.multiple_choice_p) {
            checkObj.choices = check.choices?.map((choice, i) => {
              if (choice.choice_type === 'image') {
                if (choice.filename && choice.file) {
                  formData.append(choice.filename, choice.file);
                }
              }
              const choiceObj: any = {
                choice_text: choice.choice_text.trim(),
                sequence_nr: i + 1,
                choice_type: choice.choice_type,
              };
              if (choice.filename) {
                choiceObj.filename = choice.filename;
              }
              if (choice.choice_id) {
                choiceObj.choice_id = choice.choice_id;
              }

              return choiceObj;
            });
          }

          return checkObj;
        }),
    };
    formData.append('json', JSON.stringify(payload));

    confirm({
      title: 'Confirm Form',
      icon: undefined,
      className: 'checklist-confirm-modal',
      content: 'Are you sure you want to proceed?',
      centered: true,
      cancelButtonProps: {
        className: 'checklist-confirm-modal-cancel-button',
      },
      okButtonProps: { className: 'checklist-confirm-modal-ok-button' },
      width: 480,
      onOk() {
        updateChecklist(formData);
      },
    });
  };

  useEffect(() => {
    getChecklistDetail.refetch();
  }, []);

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      {(isLoading || getChecklistDetail.isFetching) && <Loader height="50px" width="50px" color="black" />}
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/forms" className="!text-[#1890ff]">
            Forms
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit Form</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="h-full">
        {showCheckTypes && checklistDetails ? (
          <FormDetails
            checklistDetails={checklistDetails}
            checkArray={checkArray}
            setCheckArray={setCheckArray}
            savePressed={savePressed}
            setShowCheckTypes={setShowCheckTypes}
          />
        ) : (
          checklistDetails && (
            <FormInfo
              checklistDetails={checklistDetails}
              setChecklistDetails={setChecklistDetails}
              setShowCheckTypes={setShowCheckTypes}
              deleteChecklist={deleteChecklist}
            />
          )
        )}
      </div>
    </div>
  );
};

export default EditForm;
