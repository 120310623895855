import { Modal } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

export type ImagePreviewHandle = {
  toggleModal: (image: string) => void;
};

const ImagePreviewModal = forwardRef((_props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>('');

  useImperativeHandle(ref, () => ({
    toggleModal: (image: string) => {
      setIsModalVisible((prev) => !prev);
      setPreviewImage(image);
    },
  }));

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal visible={isModalVisible} title={null} footer={null} onCancel={hideModal} closable={false}>
      <img alt="Checklist" style={{ width: '100%' }} src={previewImage} />
    </Modal>
  );
});

ImagePreviewModal.displayName = 'ImagePreviewModal';

export default ImagePreviewModal;
