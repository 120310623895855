import { Button } from 'antd';
import React, { useState } from 'react';
import { ChecklistContent, SelectChecklist } from './components';
import './checklist-details.css';
import { ChecklistDetailsProps } from './checklist-details.type';

const EditChecklistDetails: React.FC<ChecklistDetailsProps> = ({
  checklistDetails,
  checkArray,
  setCheckArray,
  savePressed,
  setShowCheckTypes,
}) => {
  const [editCheckIndex, setEditCheckIndex] = useState<number | undefined>(undefined);
  return (
    <div className="h-full">
      <div className={`text-right px-10 mb-4 ${checkArray.length > 0 ? 'visible' : 'invisible'}`}>
        <Button
          className="!bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !rounded-md !px-8"
          onClick={savePressed}
        >
          Save
        </Button>
      </div>
      <div className="grid grid-cols-6 px-10 w-full h-full">
        <div className="col-span-2 mr-4 rounded bg-colorPrimaryLight">
          <ChecklistContent
            checklistDetails={checklistDetails}
            checkArray={checkArray}
            setEditCheck={setEditCheckIndex}
            setCheckArray={setCheckArray}
          />
        </div>
        <div className="col-span-4">
          <SelectChecklist
            setCheckArray={setCheckArray}
            checkArray={checkArray}
            setShowCheckTypes={setShowCheckTypes}
            editCheckIndex={editCheckIndex}
            setEditCheckIndex={setEditCheckIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default EditChecklistDetails;
