import api, { TApiResponse } from 'config/api/api.config';

export type TFulfillmentCount = {
  fulfillment_count_list: {
    scheduled_from_timestamp: string;
    scheduled_to_timestamp: string;
    checklist_template_id: number;
    checklist_template_name: string;
    checklist_template_description: string;
    assigned_count: number;
    fulfillment_count: number;
    due_count: number;
  }[];
  checklist_template_count: number;
  assigned_checklist_template_count: number;
  overdue_count: number;
};

const dashboardApi = {
  getFulfillmentCount: (): TApiResponse<TFulfillmentCount> => api.get(`checklist_fulfillment_count`),
};

export default dashboardApi;
