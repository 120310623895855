/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Bars3CenterLeftIcon,
  CameraIcon,
  Bars4Icon,
  VideoCameraIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'antd';
import { ChecklistDetailData } from '../../../form-info/FormInfo.component';
import { CheckArrayContent } from '../../form-details.type';

type CheckListContentProps = {
  checklistDetails: ChecklistDetailData;
  checkArray: CheckArrayContent[];
  setCheckArray: (checkArray: CheckArrayContent[]) => void;
  setEditCheck: (editCheck: number) => void;
};

const getCheckIcon = (type: string) => {
  switch (type) {
    case 'text':
      return <Bars3CenterLeftIcon height={22} width={22} className="flex-[0_0_12%] mr-2" />;

    case 'multiple_choice':
      return <Bars4Icon height={22} width={22} className="flex-[0_0_12%] mr-2" />;

    case 'image':
      return <CameraIcon height={22} width={22} className="flex-[0_0_12%] mr-2" />;

    case 'text-image':
      return (
        <div className="flex-[0_0_22%]">
          <CameraIcon height={22} width={22} className="inline" />
          <Bars3CenterLeftIcon height={22} width={22} className="inline" />
        </div>
      );

    case 'video':
      return <VideoCameraIcon height={22} width={22} className="flex-[0_0_12%] mr-2" />;

    case 'text-video':
      return (
        <div className="flex-[0_0_25%] mr-2">
          <VideoCameraIcon height={22} width={22} className="inline" />
          <Bars3CenterLeftIcon height={22} width={22} className="inline" />
        </div>
      );

    default:
      return <div />;
  }
};

const ChecklistContent: React.FC<CheckListContentProps> = ({
  checklistDetails,
  checkArray,
  setEditCheck,
  setCheckArray,
}) => {
  const deleteCheck = (checkTempId) => {
    setCheckArray(
      checkArray
        .filter((check) => {
          if (check.check_temp_id === checkTempId && check.edit_status === 'inserted') return false;
          return true;
        })
        .map((check) => (check.check_temp_id === checkTempId ? { ...check, edit_status: 'deleted' } : check)),
    );
  };
  return (
    <div className="text-white flex items-center flex-col w-full h-full p-4">
      <h1 className="text-white font-bold">Form Name</h1>
      <p className="capitalize">{checklistDetails.checklist_name}</p>
      {checkArray.length > 0 && (
        <div className="text-gray-200 bg-tableHeaderColor p-4 h-full w-4/5 rounded">
          <ul>
            {checkArray.map((check, index) => (
              <li key={index} hidden={check.edit_status === 'deleted'}>
                <div className="grid grid-cols-4 mt-2">
                  <div className="col-span-3 flex items-center">
                    {getCheckIcon(check.multiple_choice_p === 1 ? 'multiple_choice' : check.check_type)}
                    <span className="inline-block max-w-full text-ellipsis overflow-hidden whitespace-nowrap">
                      {check.check_type === 'image' ? 'Photo' : check.check_text}
                    </span>
                  </div>
                  <div className="col-span-1 text-right">
                    <Button
                      type="text"
                      icon={<PencilIcon height={20} width={20} className="inline text-gray-200" />}
                      onClick={() => setEditCheck(index)}
                    />
                    <Button
                      type="text"
                      icon={<TrashIcon height={22} width={22} className="inline text-gray-200" />}
                      onClick={() => deleteCheck(check.check_temp_id)}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChecklistContent;
