import React from 'react';
import { Navigate, NavigateFunction, Outlet, useNavigate } from 'react-router-dom';
import { AISidebar } from 'modules/common/components';
import session from '../../session/session.config';

const useAuth = (navigate: NavigateFunction) => {
  const user = localStorage.getItem('currentUser');
  if (user) {
    const isRouteAuthenticated = session.authenticateRoute(window.location.pathname);
    if (typeof isRouteAuthenticated === 'boolean' && !isRouteAuthenticated) {
      navigate('/');
    } else if (typeof isRouteAuthenticated === 'string') return <Navigate to={isRouteAuthenticated} />;
    return (
      <div className="flex flex-grow over">
        <div className="h-full w-fill">
          <Outlet />
        </div>
        <AISidebar />
      </div>
    );
  }
  return <Navigate to="/" />;
};

const ProtectedRoutes: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAuth(navigate);

  return isAuthenticated;
};

export default ProtectedRoutes;
