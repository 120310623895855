import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';
import './App.css';
import * as Sentry from '@sentry/react';
import RoutesContainer from './config/routes/routes-container/RoutesContainer.route';
import { nodeEnv, sentryDsn } from './config/variables/system.variable';

const queryClient = new QueryClient();

Sentry.init({
  dsn: sentryDsn,
  integrations: [new BrowserTracing()],
  environment: nodeEnv,
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <RoutesContainer />
      </Router>
      {nodeEnv === 'development' && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;
