import { Breadcrumb, Button, Modal, message } from 'antd';
import { Loader } from 'modules/common/components';
import { TUserDetails } from 'modules/user-management/user-management.type';
import userManagementApi from 'modules/user-management/user-management.api';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const UsersDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState<TUserDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const page = searchParams.get('page');
  const userId = Number(searchParams.get('userId'));

  const getUser = useQuery('Get User', async () => userManagementApi.getUser(userId), {
    enabled: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setUserDetails(data.data);
      }
    },
  });

  const deleteModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        setIsLoading(true);
        userManagementApi.deleteUser(userId).then(() => {
          setIsLoading(false);
          message.success('User deleted successfully');
          setSearchParams({ page: 'listing' });
        });
      },
    });
  };

  useEffect(() => {
    if (page === 'details' && userId) {
      getUser.refetch();
    }
  }, []);

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/user-management" className="!text-[#1890ff]">
            User Management
          </Breadcrumb.Item>
          <Breadcrumb.Item>Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="flex justify-center flex-col items-center mt-10">
        {(getUser.isFetching || isLoading) && <Loader height="50px" width="50px" color="black" showTitle />}
        <div className="bg-white p-10 max-w-4xl w-full rounded flex flex-col gap-4">
          <div className="grid grid-cols-8">
            <div className="col-span-2 font-bold">Username:</div>
            <div className="col-span-6">{userDetails?.username}</div>
          </div>
          <div className="grid grid-cols-8">
            <div className="col-span-2 font-bold">First Name:</div>
            <div className="col-span-6 capitalize">{userDetails?.first_names_en}</div>
          </div>
          <div className="grid grid-cols-8">
            <div className="col-span-2 font-bold">Last Name:</div>
            <div className="col-span-6 capitalize">{userDetails?.last_name_en || ''}</div>
          </div>
          <div className="grid grid-cols-8">
            <div className="col-span-2 font-bold">Email:</div>
            <div className="col-span-6">{userDetails?.email}</div>
          </div>
          <div className="grid grid-cols-8">
            <div className="col-span-2 font-bold">Role:</div>
            <div className="col-span-6 uppercase">{userDetails?.role_name}</div>
          </div>
        </div>
        <div className="grid grid-cols-2 max-w-4xl w-full mt-4">
          <div className="text-left pl-6">
            <Button type="text" className="!font-bold" onClick={deleteModal}>
              Delete
            </Button>
          </div>
          <div className="flex justify-end">
            <Button
              type="default"
              className="w-28 mr-2 !border-0 !font-bold"
              onClick={() => setSearchParams({ page: 'listing' })}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
              onClick={() => setSearchParams({ page: 'edit', userId: String(userId) })}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersDetails;
