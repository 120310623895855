import { Breadcrumb, message } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Loader } from 'modules/common/components';
import moment from 'moment';
import editScheduleApi, { TSchedule } from './edit-schedule.api';
import { CreateScheduleForm } from '../create-schedule/components';

const EditSchedule = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleDetails, setScheduleDetails] = useState<TSchedule>();

  const getScheduleDetail = useQuery('Get Schedule Detail', async () => editScheduleApi.getSchedule(id), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.checklist_schedules.length) {
        setScheduleDetails(data.checklist_schedules[0]);
      }
    },
  });

  const deleteSchedule = async () => {
    const { data, status } = await editScheduleApi.deleteSchedule(id);
    if (status === 200 && data.status === 'successful') {
      message.success('Schedule deleted successfully');
      navigate('/schedule');
    } else {
      message.error('Something went wrong');
    }
  };

  const confirmSchedule = async (values: any, startDate: Date, endDate: Date | null, selectedDays: string) => {
    const payload = {
      schedule_name: values.schedule_name.trim(),
      schedule_description: values.schedule_desc ? values.schedule_desc.trim() : '',
      checklist_id: Number(values.checklist),
      week_days: selectedDays,
      from_date: moment(startDate).utc().format('YYYY-MM-DD'),
      to_date: endDate ? moment(endDate).utc().format('YYYY-MM-DD') : null,
      from_time: values.from_time,
      to_time: values.to_time,
      repeat_every: values.repeat.times,
      repeat_every_uom_id: values.repeat.type,
      all_hours_p: values.all_hours_p,
      checklist_assignments: values.checklist_assignments,
      status: values.schedule_status ? 'active' : 'inactive',
    };

    setIsLoading(true);
    const { data, status } = await editScheduleApi.updateSchedule(payload, id);
    setIsLoading(false);
    if (status === 200 && data.status === 'successful') {
      message.success('Scheduled updated successfully');
      navigate('/schedule');
    } else {
      message.error('Something went wrong');
    }
  };

  useEffect(() => {
    getScheduleDetail.refetch();
  }, []);

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      {(getScheduleDetail.isFetching || isLoading) && <Loader height="50px" width="50px" color="black" />}
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/schedule" className="!text-[#1890ff]">
            Schedule
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit Schedule</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="h-full">
        <CreateScheduleForm
          createSchedule={confirmSchedule}
          scheduleDetails={scheduleDetails}
          deleteSchedule={deleteSchedule}
        />
      </div>
    </div>
  );
};

export default EditSchedule;
