import api, { TApiResponse } from '../../../config/api/api.config';

export type TChecklistApproval = {
  approval_status: string;
  approval_notes: string;
  checklist_id: number;
};

const submissionDetailApi = {
  getChecklistDetail: (checklistId: string) => api.get(`checklist/${checklistId}`),
  checklistApproval: (payload: TChecklistApproval): TApiResponse => api.put(`checklist_approval`, payload),
};

export default submissionDetailApi;
