import React, { useRef, useState } from 'react';
import { Breadcrumb } from 'antd';
import { useQuery } from 'react-query';
import reportsListingApi from './reports-listing.api';
import { Loader } from '../../common/components';
import ReportsListingTable from './components/reports-listing-table/ReportsListingTable.component';

const ReportsListing = () => {
  const [checklists, setChecklists] = useState([]);
  const gridRef = useRef();

  const getChecklists = useQuery('Get Checklists', async () => reportsListingApi.getChecklists(), {
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setChecklists(data.result_list);
      }
    },
  });

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>Reports</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {getChecklists.isLoading && <Loader height="50px" width="50px" color="black" showTitle />}
      {getChecklists.isFetched && <ReportsListingTable gridRef={gridRef} rowData={checklists} />}
    </div>
  );
};

export default ReportsListing;
