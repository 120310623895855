import React, { useState } from 'react';
import { Breadcrumb, message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ChecklistDetailData } from './components/checklist-info/ChecklistInfo.component';
import { ChecklistDetails, ChecklistInfo } from './components';
import { CheckArrayContent } from './components/checklist-detail/checklist-details.type';
import addChecklistApi from './add-checklist.apis';
import Loader from '../../common/components/loader/Loader.component';
import { CheckArray, ChecklistPayload } from './add-checklist.type';

const { confirm } = Modal;

const AddChecklist = () => {
  const navigate = useNavigate();
  const [checklistDetails, setChecklistDetails] = useState<ChecklistDetailData>();
  const [checkArray, setCheckArray] = useState<CheckArrayContent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCheckTypes, setShowCheckTypes] = useState(false);

  const addChecklist = async (formData: any) => {
    const { data, status } = await addChecklistApi.addChecklist(formData);
    setIsLoading(false);
    if (status !== 200 || data.status !== 'successful') {
      message.error('Failed to add checklist. Please try again');
    } else {
      navigate('/checklists');
    }
  };

  const savePressed = () => {
    const formData = new FormData();
    const payload: ChecklistPayload = {
      checklist_name: checklistDetails?.checklist_name.trim(),
      checklist_description: checklistDetails?.checklist_desc ? checklistDetails?.checklist_desc.trim() : '',
      checklist_type_id: 1,
      checklist_point_id: checklistDetails?.checklist_point_id ? checklistDetails.checklist_point_id : [],
      status: checklistDetails?.checklist_status ? 'active' : 'inactive',
      checks: checkArray
        .filter((check) => {
          if (!check.check_id && check.edit_status === 'deleted') return false;
          return true;
        })
        .map((check, index) => {
          const checkObj: CheckArray = {
            check_name: check.check_name,
            check_text: check.check_text ? check.check_text : '',
            check_type: check.check_type,
            edit_status: check.edit_status,
            multiple_choice_p: check.multiple_choice_p ? 1 : 0,
            filename: check.filename ? check.filename : [],
            status: check.status ? 'active' : 'inactive',
            sequence_nr: index + 1,
            answer_type: check.answer_type,
            required_p: check.required_p,
          };
          if (check.check_type === 'image' || check.check_type === 'text-image') {
            if (check.filename && check.file) {
              check.file.forEach((file) => {
                formData.append(file.name, file);
              });
            }
          }
          if (checkObj.multiple_choice_p) {
            checkObj.choices = check.choices?.map((choice, i) => {
              if (choice.choice_type === 'image') {
                if (choice.filename && choice.file) {
                  formData.append(choice.filename, choice.file);
                }
              }
              return {
                choice_text: choice.choice_text.trim(),
                sequence_nr: i + 1,
                choice_type: choice.choice_type,
                filename: choice.filename ? choice.filename : '',
              };
            });
          }

          return checkObj;
        }),
    };
    formData.append('json', JSON.stringify(payload));

    confirm({
      title: 'Confirm Checklist',
      icon: undefined,
      className: 'checklist-confirm-modal',
      content: 'Are you sure you want to proceed?',
      centered: true,
      cancelButtonProps: {
        className: 'checklist-confirm-modal-cancel-button',
      },
      okButtonProps: { className: 'checklist-confirm-modal-ok-button' },
      width: 480,
      onOk() {
        setIsLoading(true);
        addChecklist(formData);
      },
    });
  };

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      {isLoading && <Loader height="50px" width="50px" color="black" showTitle title="Adding checklist..." />}
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/checklists" className="!text-[#1890ff]">
            Checklists
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add Checklist</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="h-full">
        {showCheckTypes && checklistDetails ? (
          <ChecklistDetails
            checklistDetails={checklistDetails}
            checkArray={checkArray}
            setCheckArray={setCheckArray}
            savePressed={savePressed}
            setShowCheckTypes={setShowCheckTypes}
          />
        ) : (
          <ChecklistInfo
            checklistDetails={checklistDetails}
            setChecklistDetails={setChecklistDetails}
            setShowCheckTypes={setShowCheckTypes}
          />
        )}
      </div>
    </div>
  );
};

export default AddChecklist;
