/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import { Breadcrumb, Button, Space, Tag, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useCurrentUser from 'modules/common/hooks/use-current-user/useCurrentUser.hook';
import ImagePreviewModal, {
  ImagePreviewHandle,
} from '../../checklists/fill-checklist/components/image-preview-modal/ImagePreviewModal.component';
import { Loader, Modal } from '../../common/components';
import SubmissionAnswers from './components/submission-answers/SubmissionAnswers.component';
import submissionDetailApi from './submission-detail.api';

type ChecklistSubmissionProps = {
  checklist_name: string;
  checklist_description: string;
  checklist_type_name: 'form' | 'checklist';
  approval_required_p: number;
  checklist_status: string;
  approval_list: {
    user_id: number;
    approval_status: string | null;
    approval_timestamp: string | null;
    approval_notes: string | null;
    user_name: string;
  }[];
  user_data: {
    assigned_user_id: number;
    submitted_on: string;
    submitted_by: string;
  };
  checks: {
    check_id: number;
    check_text: string;
    check_type: string;
    media: any[];
    answer_type: string;
    answer_comment: string;
  }[];
};

const SubmissionDetail = () => {
  const [checklistDetails, setChecklistDetails] = useState<ChecklistSubmissionProps>();
  const [checklistScore, setChecklistScore] = useState<string>('');
  const [canApprove, setCanApprove] = useState(false);
  const [approvalNotes, setApprovalNotes] = useState('');
  const [isApprovalOpen, setIsApprovalOpen] = useState(false);
  const [viewApprovalsModal, setViewApprovalsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { id = '' } = useParams();
  const imagePreviewModalRef = useRef<ImagePreviewHandle>();

  const getChecklistDetail = useQuery('Get Checklists', async () => submissionDetailApi.getChecklistDetail(id), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setChecklistDetails(data.checklist);
        let score = 0;
        let total = 0;
        data.checklist.checks.forEach((check: any) => {
          if (check.answer_type === 'single-selection') {
            total += 1;
            const answer = check.choices.find((choice: any) => choice.choice_id === check.answer_choice_id[0]);
            if (answer.choice_text === 'yes') {
              score += 1;
            }
          }
        });
        if (score && total) {
          setChecklistScore(`(${score}/${total}) ${Math.round((score / total) * 100)}%`);
        }
      }
    },
  });

  const checklistApproval = async (approvalStatus: string) => {
    setIsLoading(true);
    const payload = {
      approval_status: approvalStatus,
      approval_notes: approvalNotes,
      checklist_id: Number(id),
    };
    const { data, status } = await submissionDetailApi.checklistApproval(payload);
    setIsLoading(false);
    if (status === 200 && data.status === 'successful') {
      message.success(`Form ${payload.approval_status} successfully`);
      getChecklistDetail.refetch();
    } else {
      message.error('Something went wrong. Please try again later.');
    }
  };

  useEffect(() => {
    if (checklistDetails) {
      let approve = false;
      checklistDetails.approval_list?.forEach((item) => {
        if (item.user_id === Number(currentUser?.user_details.user_id || 0) && item.approval_status === 'pending') {
          approve = true;
        }
      });
      setCanApprove(approve);
    }
  }, [checklistDetails]);

  return (
    <div className="m-5">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/submissions" className="!text-[#1890ff]">
            Submissions
          </Breadcrumb.Item>
          <Breadcrumb.Item>Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <ImagePreviewModal ref={imagePreviewModalRef} />
      {(getChecklistDetail.isFetching || isLoading) && <Loader height="50px" width="50px" color="black" />}
      {getChecklistDetail.isFetched && (
        <div className="m-auto w-3/5 bg-white px-8 pt-8 pb-14 rounded">
          <div className="grid grid-cols-4">
            <div className="col-span-4">
              <div className="flex justify-between">
                <div className="text-2xl font-bold capitalize">
                  {checklistDetails?.checklist_name}
                  {checklistDetails?.checklist_type_name === 'form' && (
                    <span className="ml-2">
                      <Tag
                        color={
                          checklistDetails?.checklist_status === 'approved'
                            ? 'green'
                            : checklistDetails?.checklist_status === 'rejected'
                            ? 'red'
                            : checklistDetails?.checklist_status === 'pending-approval'
                            ? 'orange'
                            : 'blue'
                        }
                        className="capitalize"
                      >
                        {checklistDetails?.checklist_status}
                      </Tag>
                    </span>
                  )}
                </div>
                {canApprove && (
                  <div className="flex gap-2">
                    <Button
                      type="primary"
                      className="!bg-colorPrimaryLight !border-colorPrimaryLight hover:opacity-80"
                      onClick={() => checklistApproval('approved')}
                    >
                      Approve
                    </Button>
                    <Button type="ghost" onClick={() => setIsApprovalOpen(true)}>
                      Reject
                    </Button>
                  </div>
                )}
              </div>
              <div className="text-md first-letter:capitalize">{checklistDetails?.checklist_description}</div>
            </div>
            {/* <div className="font-bold text-xl text-right">{checklistScore}</div> */}
          </div>
          <hr className="my-2 -mx-2" />
          <div className="flex justify-between">
            <div>
              <span className="text-gray-600">Submitted by:</span>
              <span className="capitalize ml-1 font-medium">{checklistDetails?.user_data.submitted_by}</span>
            </div>
            <div>
              <span className="text-gray-600">Submitted on:</span>
              <span className="ml-1 font-medium">
                {moment.utc(checklistDetails?.user_data.submitted_on).local().format('YYYY-MM-DD hh:mm a')}
              </span>
            </div>
          </div>
          <hr className="my-2 -mx-2" />
          {checklistDetails?.checks?.map((check) => (
            <>
              <div
                key={check.check_id}
                className={`${check.answer_type === 'single-selection' ? 'grid grid-cols-1 gap-2' : ''} mt-4`}
              >
                <div className="col-span-3">
                  <p className="m-0 text-base font-semibold first-letter:capitalize">{check.check_text}</p>
                  {(check.check_type === 'image' || check.check_type === 'text-image') &&
                    check.media?.map((item: any, index) => (
                      <Space key={item.check_media_id} direction="horizontal">
                        <button
                          type="button"
                          onClick={() => {
                            if (imagePreviewModalRef.current) imagePreviewModalRef.current.toggleModal(item.url);
                          }}
                        >
                          <img
                            src={item.url}
                            alt="Check"
                            className={`h-40 w-40 rounded ${index === 0 ? 'ml-0' : 'ml-4'} mb-4`}
                          />
                        </button>
                      </Space>
                    ))}
                </div>
                <SubmissionAnswers answerType={check.answer_type} checkDetails={check} />
              </div>
              {check.answer_comment && (
                <div>
                  <div className="p-2 bg-gray-200 mt-2 rounded flex">
                    <div>{check.answer_comment}</div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}
      {(checklistDetails?.approval_list || []).length > 0 && (
        <div className="m-auto w-3/5 flex justify-end">
          <Button type="text" onClick={() => setViewApprovalsModal(true)}>
            View Approvals
          </Button>
        </div>
      )}

      <Modal
        title="Confirmation"
        open={isApprovalOpen}
        className="!w-50v overflow-y-auto bg-card xl:!w-[40vw] bg-white"
        onClose={() => setIsApprovalOpen(false)}
        footer={null}
        closable
      >
        <div className="flex flex-col gap-4">
          <span className="text-sm font-medium text-gray-500">Remarks</span>
          <textarea
            className="h-40 w-full rounded-md border border-gray-300 bg-white p-2 text-black"
            placeholder="Type your message here"
            value={approvalNotes}
            onChange={(e) => setApprovalNotes(e.target.value)}
          />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="btn-primary whitespace-nowrap px-3 text-sm"
              onClick={() => {
                setIsApprovalOpen(false);
                setApprovalNotes('');
              }}
            >
              Cancel
            </button>

            <Button
              type="primary"
              className="!bg-colorPrimaryLight !border-colorPrimaryLight hover:opacity-80 whitespace-nowrap px-3 text-sm"
              onClick={() => {
                checklistApproval('rejected');
                setIsApprovalOpen(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Approvals"
        open={viewApprovalsModal}
        className="!w-50v overflow-y-auto bg-card text-black xl:!w-[40vw] bg-white"
        onClose={() => setViewApprovalsModal(false)}
        footer={null}
        closable
      >
        <div className="flex flex-col gap-4 border-t pt-4">
          {checklistDetails?.approval_list?.map((item) => (
            <div key={item.user_id} className="ml-4 border-b py-2">
              <div className="flex gap-4">
                <div className="capitalize">Name: {item.user_name}</div>
                <div>
                  Status:{' '}
                  <Tag
                    color={
                      item.approval_status === 'approved'
                        ? 'green'
                        : item.approval_status === 'rejected'
                        ? 'red'
                        : 'blue'
                    }
                    className="capitalize"
                  >
                    {item.approval_status}
                  </Tag>
                </div>
              </div>
              {item.approval_notes ? <div>Note: {item.approval_notes}</div> : null}
            </div>
          ))}
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="btn-primary whitespace-nowrap px-3 text-sm"
              onClick={() => {
                setViewApprovalsModal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SubmissionDetail;
