import React, { useRef, useState } from 'react';
import { Breadcrumb, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import ScheduleListingTable from './components/schedule-listing-table/ScheduleListingTable.component';
import scheduleListingApi from './schedule-listing.api';
import { Loader } from '../../common/components';

const ScheduleListing = () => {
  const [schedules, setSchedules] = useState([]);
  const gridRef = useRef();
  const navigate = useNavigate();

  const getSchedules = useQuery('Get Schedules', async () => scheduleListingApi.getSchedules(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setSchedules(data.checklist_schedules.reverse());
      }
    },
  });

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>Schedule</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="text-right">
        <Button
          className="!bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !rounded-md !px-8"
          onClick={() => navigate(`/create-schedule`)}
        >
          Create Schedule
        </Button>
      </div>
      {getSchedules.isFetching && <Loader height="50px" width="50px" color="black" showTitle />}
      {getSchedules.isFetched && <ScheduleListingTable gridRef={gridRef} rowData={schedules} />}
    </div>
  );
};

export default ScheduleListing;
