import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Button, Tag, Tooltip } from 'antd';
import { PencilIcon } from '@heroicons/react/20/solid';
import { useAvailableHeight } from 'modules/common/hooks';
import { useNavigate } from 'react-router-dom';

const getStatusTagColor = (status: string | undefined) => {
  switch (status) {
    case 'inactive':
      return 'error';

    default:
      return 'success';
  }
};

const ScheduleListingTable = ({ rowData, gridRef }: any) => {
  const [divRef, availableHeight] = useAvailableHeight();
  const navigate = useNavigate();

  const statusCellRenderer = (params: {
    value: string | undefined;
    data: { cancelled_timestamp: string | null | undefined };
  }) => (
    <div>
      <Tag color={getStatusTagColor(params.value)}>{params.value}</Tag>
    </div>
  );

  const assignedToCell = (params: any) => {
    let userNames = [] as any;
    let roleNames = [] as any;
    const usersIdList = params.value;
    const rolesIdList = params.data.role_id_list;
    if (usersIdList.length === 0 && rolesIdList.length === 0) {
      const facilityAssignmentList = params.data.facility_assignment_list;
      if (facilityAssignmentList.length > 0) {
        facilityAssignmentList.forEach((facilityAssignment: any) => {
          userNames.push(
            facilityAssignment.user_id_list.map((user: any) => `${user.first_names_en} ${user.last_name_en}`),
          );
          roleNames.push(facilityAssignment.role_id_list.map((role: any) => role.role_name));
        });
      }
    } else {
      userNames = params.value.map((user: any) => `${user.first_names_en} ${user.last_name_en}`);
      roleNames = params.data.role_id_list.map((role: any) => role.role_name);
    }
    return (
      <div className="flex mt-2">
        {userNames.map((user: any) => (
          <Tooltip title={user} key={user}>
            <img
              src={`https://ui-avatars.com/api/?name=${user}&size=24&rounded=true&background=efefef`}
              alt={user}
              className="ml-1"
            />
          </Tooltip>
        ))}
        {roleNames.map((role: any) => (
          <Tooltip title={role} key={role}>
            <img
              src={`https://ui-avatars.com/api/?name=${role}&size=24&rounded=true&background=ACDDDE`}
              alt={role}
              className="ml-1"
            />
          </Tooltip>
        ))}
      </div>
    );
  };

  const actionCellRenderer = (params) => {
    return (
      <Button
        type="text"
        icon={
          <PencilIcon
            height={20}
            width={20}
            className="float-right"
            onClick={() => navigate(`/edit-schedule/${params.value}`)}
          />
        }
      />
    );
  };

  const columnDef: ColDef[] = [
    {
      headerName: 'ID',
      hide: true,
      field: 'schedule_id',
      headerClass: 'listing-table-header',
      filter: true,
      sortable: true,
      flex: 0.4,
    },
    {
      headerName: 'SCHEDULE NAME',
      field: 'schedule_name',
      headerClass: 'listing-table-header',
      filter: true,
      cellClass: 'capitalize',
      flex: 1,
    },
    {
      headerName: 'SCHEDULE DESCRIPTION',
      field: 'schedule_description',
      headerClass: 'listing-table-header',
      filter: true,
      flex: 1,
    },
    {
      headerName: 'CHECKLIST NAME',
      field: 'checklist_template_name',
      headerClass: 'listing-table-header',
      cellClass: 'capitalize',
      sortable: true,
      filter: true,
      flex: 0.8,
    },
    {
      headerName: 'SCHEDULED AT',
      field: 'scheduled_at',
      headerClass: 'listing-table-header',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'ASSIGNED TO',
      field: 'user_id_list',
      headerClass: 'listing-table-header',
      cellRenderer: assignedToCell,
      filter: true,
      sortable: true,
      flex: 0.7,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      headerClass: 'listing-table-header',
      cellRenderer: statusCellRenderer,
      sortable: true,
      flex: 0.5,
    },
    {
      headerName: '',
      field: 'schedule_id',
      headerClass: 'listing-table-header',
      cellRenderer: actionCellRenderer,
      sortable: true,
      flex: 0.3,
    },
  ];

  return (
    <div className="mt-4 product-request-table" ref={divRef as any} style={{ height: Number(availableHeight) - 20 }}>
      <AgGridReact
        ref={gridRef}
        enableCellTextSelection
        columnDefs={columnDef}
        rowClass="listing-table-row"
        className="ag-theme-material"
        headerHeight={50}
        rowHeight={50}
        defaultColDef={{ resizable: true, sortable: true }}
        suppressRowTransform
        rowData={rowData}
      />
    </div>
  );
};

export default ScheduleListingTable;
