import { Button, Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CheckArrayContent } from '../../../../form-details.type';

export type AnswerTypeProps = {
  value: string;
  label: string;
};

export type CheckProps = {
  clearSelected: () => void;
  setCheckArray: (checkArray: CheckArrayContent[]) => void;
  checkArray: CheckArrayContent[];
  answerTypeList: AnswerTypeProps[];
  editCheckIndex: number | undefined;
  check_type: string;
};

const TextCheck: React.FC<CheckProps> = ({
  clearSelected,
  setCheckArray,
  checkArray,
  answerTypeList,
  check_type,
  editCheckIndex,
}) => {
  const [editStatus, setEditStatus] = useState<string>('inserted');
  const [form] = Form.useForm();

  useEffect(() => {
    if (editCheckIndex !== undefined) {
      const selectedCheck = checkArray[editCheckIndex];
      setEditStatus(selectedCheck.check_id ? 'updated' : 'inserted');
      form.setFieldValue('check_text', selectedCheck.check_text);
      form.setFieldValue('answer_type', selectedCheck.answer_type);
      form.setFieldValue('checklist_status', selectedCheck.status);
      form.setFieldValue('required', selectedCheck.required_p === 1);
    }
  }, [editCheckIndex]);

  const handleFinish = (values: any) => {
    const newCheck = {
      check_type,
      check_name: values.check_text.trim(),
      edit_status: editStatus as any,
      check_text: values.check_text.trim(),
      status: values.checklist_status,
      answer_type: values.answer_type,
      required_p: values.required === true ? 1 : 0,
    };

    if (editCheckIndex !== undefined) {
      const currentCheck = checkArray[editCheckIndex];
      setCheckArray(
        checkArray.map((check, index) =>
          index === editCheckIndex
            ? { ...newCheck, check_id: currentCheck.check_id, check_temp_id: currentCheck.check_temp_id }
            : check,
        ),
      );
    } else {
      setCheckArray([...checkArray, { ...newCheck, check_temp_id: uuidv4() }]);
    }
    clearSelected();
  };
  return (
    <>
      <div className="bg-white p-10 max-w-4xl w-full rounded">
        <Form
          labelCol={{ span: 6, style: { textAlign: 'left' } }}
          wrapperCol={{ span: 14 }}
          form={form}
          onFinish={handleFinish}
        >
          <Form.Item
            label="Question"
            name="check_text"
            className="font-bold"
            rules={[{ required: true, message: 'Please enter text!' }]}
          >
            <Input className="font-normal" />
          </Form.Item>
          <Form.Item
            label="Answer Type"
            name="answer_type"
            className="font-bold"
            rules={[{ required: true, message: 'Please select answer type!' }]}
          >
            <Select>
              {answerTypeList
                .filter((answerType) => !answerType.value.includes('selection'))
                .map((answerType) => (
                  <Select.Option key={answerType.value} value={answerType.value}>
                    {answerType.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Required" name="required" className="font-bold" valuePropName="checked" initialValue>
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
          </Form.Item>
          <Form.Item label="Status" name="checklist_status" className="font-bold" valuePropName="checked" initialValue>
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
          </Form.Item>
        </Form>
      </div>
      <div className="max-w-4xl w-full mt-4">
        <div className="flex justify-end">
          <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={clearSelected}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
            onClick={() => form.submit()}
          >
            {editCheckIndex !== undefined ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TextCheck;
