import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserDetail, UserListing, UserUpsert } from './components';

const UserManagement = () => {
  const [currentPage, setCurrentPage] = useState<'listing' | 'add' | 'edit' | 'details'>('listing');
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  useEffect(() => {
    setCurrentPage((page as 'listing' | 'add' | 'edit' | 'details') || 'listing');
  }, [page]);

  return (
    <div>
      {currentPage === 'listing' && <UserListing />}
      {currentPage === 'details' && <UserDetail />}
      {(currentPage === 'add' || currentPage === 'edit') && <UserUpsert />}
    </div>
  );
};

export default UserManagement;
