import { Button, Form, Input, message, Select, Switch, Upload } from 'antd';
import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';
import { v4 as uuidv4 } from 'uuid';
import { CheckProps } from '../text-check/TextCheck.component';
import { Media } from '../../../../checklist-details.type';

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const PhotoCheck: React.FC<CheckProps> = ({
  clearSelected,
  setCheckArray,
  checkArray,
  answerTypeList,
  check_type,
  editCheckIndex,
}) => {
  const [imagesUrl, setImagesUrl] = useState<Media[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState<number[]>([]);
  const [editStatus, setEditStatus] = useState<string>('inserted');
  const [form] = Form.useForm();

  useEffect(() => {
    if (editCheckIndex !== undefined) {
      const selectedCheck = checkArray[editCheckIndex];
      setEditStatus(selectedCheck.check_id ? 'updated' : 'inserted');
      form.setFieldValue('check_text', selectedCheck.check_text);
      form.setFieldValue('answer_type', selectedCheck.answer_type);
      form.setFieldValue('checklist_status', selectedCheck.status);
      form.setFieldValue('required', selectedCheck.required_p === 1);
      if (selectedCheck.media) {
        setImagesUrl(selectedCheck.media.map((m) => ({ ...m, uid: String(m.check_media_id) })));
        setSelectedFiles(
          selectedCheck.media.map((media) => ({
            uid: String(media.check_media_id),
            name: '',
            status: 'done',
            url: media.url,
          })),
        );
      }
    }
  }, [editCheckIndex]);

  const handleFinish = (values: any) => {
    const newFiles = selectedFiles
      .filter((file) => file.originFileObj)
      .map((file, index) => {
        const blob = file.originFileObj?.slice(0, file.size, file.type) || '';
        const fileExtension = file.name.split('.').pop();
        const fileName = `img_${new Date().getTime()}_${index}.${fileExtension}`;
        const updatedFile = new File([blob], fileName, { type: 'image/png' });
        return updatedFile;
      });
    const newCheck: any = {
      check_type,
      check_name: values.check_text ? values.check_text : 'Photo',
      edit_status: editStatus as any,
      status: !!values.checklist_status,
      filename: newFiles.map((file) => file.name),
      media_to_be_deleted: imagesToBeDeleted,
      file: newFiles,
      media: imagesUrl.map(({ url }) => ({
        url,
      })),
      answer_type: values.answer_type,
      required_p: values.required === true ? 1 : 0,
    };
    if (newCheck.media.length === 0 && newCheck.filename.length === 0) {
      message.error('Please select at least 1 image');
      return;
    }
    if (check_type === 'text-image') {
      newCheck.check_text = values.check_text;
    }
    if (editCheckIndex !== undefined) {
      const currentCheck = checkArray[editCheckIndex];
      setCheckArray(
        checkArray.map((check, index) =>
          index === editCheckIndex
            ? { ...newCheck, check_id: currentCheck.check_id, check_temp_id: currentCheck.check_temp_id }
            : check,
        ),
      );
    } else {
      setCheckArray([...checkArray, { ...newCheck, check_temp_id: uuidv4() }]);
    }
    clearSelected();
  };

  const uploadButton = (
    <div>
      <PlusIcon height={20} width={20} className="m-auto" />
      <div style={{ marginTop: 8 }}>Select Image</div>
    </div>
  );

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    if (file.status !== 'removed') {
      const isLt2M = (file?.size || 0) / 1024 / 1024 < 2;
      if (isLt2M) {
        setSelectedFiles(info.fileList);
        getBase64(file as RcFile, (url) => {
          // setLoading(false);
          setImagesUrl([...imagesUrl, { url, uid: file.uid }]);
        });
      } else {
        message.error('Image must smaller than 2MB!');
      }
    }
  };

  const handleRemove = (file: any) => {
    if (!file.originFileObj) {
      if (!imagesToBeDeleted.includes(Number(file.uid))) {
        setImagesToBeDeleted([...imagesToBeDeleted, Number(file.uid)]);
      }
    }
    setSelectedFiles(selectedFiles.filter((f) => f.uid !== file.uid));
    setImagesUrl(imagesUrl.filter((image) => image.uid !== file.uid));
    return true;
  };

  return (
    <>
      <div className="bg-white p-10 max-w-4xl w-full rounded">
        <Form
          labelCol={{ span: 6, style: { textAlign: 'left' } }}
          wrapperCol={{ span: 14 }}
          form={form}
          onFinish={handleFinish}
        >
          {check_type === 'text-image' && (
            <Form.Item
              label="Question"
              name="check_text"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter text!' }]}
            >
              <Input className="font-normal" />
            </Form.Item>
          )}
          <Form.Item label="Image" name="check_image" className="font-bold" required>
            <Upload
              name="choice"
              fileList={selectedFiles}
              accept="image/png, image/jpeg"
              listType="picture-card"
              className="avatar-uploader"
              onPreview={() => {
                return false;
              }}
              onRemove={handleRemove}
              beforeUpload={() => {
                return false;
              }}
              onChange={handleChange}
            >
              {selectedFiles.length >= 8 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Answer Type"
            name="answer_type"
            className="font-bold"
            rules={[{ required: true, message: 'Please select answer type!' }]}
          >
            <Select>
              {answerTypeList
                .filter((answerType) => !answerType.value.includes('selection'))
                .map((answerType) => (
                  <Select.Option key={answerType.value} value={answerType.value}>
                    {answerType.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Required" name="required" className="font-bold" valuePropName="checked" initialValue>
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
          </Form.Item>
          <Form.Item label="Status" name="checklist_status" className="font-bold" initialValue>
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
          </Form.Item>
        </Form>
      </div>
      <div className="max-w-4xl w-full mt-4">
        <div className="flex justify-end">
          <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={clearSelected}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
            onClick={() => form.submit()}
          >
            {editCheckIndex !== undefined ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PhotoCheck;
