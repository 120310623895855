import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { Button } from 'antd';
import moment, { MomentInput } from 'moment';
import { useAvailableHeight } from 'modules/common/hooks';
import { Link } from 'react-router-dom';

const SubmissionListingTable = ({ rowData, gridRef }: any) => {
  const [divRef, availableHeight] = useAvailableHeight();

  const actionCellRenderer = (params: { data: { checklist_id: number } }) => {
    return (
      <Link to={`/submissions/${params.data.checklist_id}`}>
        <Button type="primary" className="!bg-colorPrimaryLight !border-colorPrimaryLight hover:opacity-80">
          See Details{' '}
        </Button>
      </Link>
    );
  };

  const dateTimeFormat = (params: { value: MomentInput }) => {
    const timeFormat = moment.utc(params.value).local().format('ddd DD MMM YYYY h:mm A');
    return timeFormat;
  };

  const columnDef: ColDef[] = [
    {
      headerName: 'ID',
      hide: true,
      field: 'checklist_assignment_id',
      headerClass: 'listing-table-header',
      filter: true,
      sortable: true,
      flex: 0.2,
    },
    {
      headerName: 'CHECKLIST NAME',
      field: 'checklist_name',
      headerClass: 'listing-table-header',
      cellClass: 'capitalize',
      filter: true,
      flex: 0.7,
    },
    {
      headerName: 'SUBMITTED BY',
      field: 'submitted_by',
      headerClass: 'listing-table-header',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'SUBMITTED ON',
      field: 'submitted_on',
      headerClass: 'listing-table-header',
      sortable: true,
      cellRenderer: dateTimeFormat,
      flex: 1,
    },
    {
      headerName: '',
      field: 'action',
      headerClass: 'listing-table-header',
      cellRenderer: actionCellRenderer,
      sortable: true,
      flex: 0.5,
    },
  ];

  return (
    <div className="mt-4 product-request-table" ref={divRef as any} style={{ height: Number(availableHeight) - 20 }}>
      <AgGridReact
        ref={gridRef}
        enableCellTextSelection
        columnDefs={columnDef}
        rowClass="listing-table-row"
        className="ag-theme-material"
        headerHeight={50}
        rowHeight={50}
        defaultColDef={{ resizable: true, sortable: true }}
        suppressRowTransform
        rowData={rowData}
      />
    </div>
  );
};

export default SubmissionListingTable;
