import React from 'react';
import { Breadcrumb, message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { CreateScheduleForm } from './components';
import createScheduleApi from './create-schedule.api';

const { confirm } = Modal;

const CreateSchedule = () => {
  const navigate = useNavigate();

  const createSchedule = async (payload: any) => {
    const { data, status } = await createScheduleApi.createSchedule(payload);
    if (status !== 200 || data.status !== 'successful') {
      message.error('Failed to create schedule. Please try again');
    } else {
      navigate('/schedule');
    }
  };

  const confirmSchedule = (values: any, startDate: Date, endDate: Date | null, selectedDays: string) => {
    const payload = {
      schedule_name: values.schedule_name.trim(),
      schedule_description: values.schedule_desc ? values.schedule_desc.trim() : '',
      checklist_id: Number(values.checklist),
      week_days: selectedDays,
      from_date: moment(startDate).utc().format('YYYY-MM-DD'),
      to_date: endDate ? moment(endDate).utc().format('YYYY-MM-DD') : null,
      from_time: values.from_time,
      to_time: values.to_time,
      repeat_every: values.repeat.times,
      repeat_every_uom_id: values.repeat.type,
      all_hours_p: values.all_hours_p,
      checklist_assignments: values.checklist_assignments,
      status: values.schedule_status ? 'active' : 'inactive',
    };

    confirm({
      title: 'Confirm Schedule',
      icon: undefined,
      className: 'checklist-confirm-modal',
      content: 'Are you sure you want to proceed?',
      centered: true,
      cancelButtonProps: {
        className: 'checklist-confirm-modal-cancel-button',
      },
      okButtonProps: { className: 'checklist-confirm-modal-ok-button' },
      width: 480,
      onOk() {
        createSchedule(payload);
      },
    });
  };

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/schedule" className="!text-[#1890ff]">
            Schedule
          </Breadcrumb.Item>
          <Breadcrumb.Item>Create Schedule</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="h-full">
        <CreateScheduleForm createSchedule={confirmSchedule} />
      </div>
    </div>
  );
};

export default CreateSchedule;
