import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Breadcrumb, Button } from 'antd';
import { Loader } from 'modules/common/components';
import { useAvailableHeight } from 'modules/common/hooks';
import { TUser } from 'modules/user-management/user-management.type';
import userManagementApi from 'modules/user-management/user-management.api';
import moment, { MomentInput } from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const UserListing = () => {
  const [divRef, availableHeight] = useAvailableHeight();
  const [users, setUsers] = useState<TUser[]>([]);
  const [, setSearchParams] = useSearchParams();

  const getUsers = useQuery('Get Users', async () => userManagementApi.getUsers(), {
    enabled: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setUsers(data.result_list.filter((user) => user.role_id !== 1 && user.meta_status !== 'deleted'));
      }
    },
  });

  const actionCellRenderer = (params) => {
    return (
      <Button
        type="primary"
        className="!bg-colorPrimaryLight !border-colorPrimaryLight hover:opacity-80"
        onClick={() =>
          setSearchParams({
            page: 'details',
            userId: String(params.data.user_id),
          })
        }
      >
        See Details{' '}
      </Button>
    );
  };

  const dateTimeFormat = (params: { value: MomentInput }) => {
    const timeFormat = moment.utc(params.value).local().format('ddd DD MMM YYYY h:mm A');
    return timeFormat;
  };

  const columnDef: ColDef[] = [
    {
      headerName: 'Username',
      field: 'username',
      headerClass: 'listing-table-header',
      filter: true,
      flex: 0.7,
    },
    {
      headerName: 'Full Name',
      field: 'first_names_en',
      headerClass: 'listing-table-header',
      valueFormatter: (params) => `${params.value} ${params.data.last_name_en || ''}`,
      filter: true,
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Phone',
      hide: true,
      field: 'phone_nr',
      headerClass: 'listing-table-header',
      valueFormatter: (params) => params.value || '-',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Created At',
      field: 'insertion_timestamp',
      headerClass: 'listing-table-header',
      sortable: true,
      cellRenderer: dateTimeFormat,
      flex: 1,
    },
    {
      headerName: '',
      field: 'action',
      headerClass: 'listing-table-header',
      cellRenderer: actionCellRenderer,
      sortable: true,
      flex: 0.5,
    },
  ];

  useEffect(() => {
    getUsers.refetch();
  }, []);

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>User Management</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="text-right">
        <Button
          className="!bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !rounded-md !px-8"
          onClick={() => setSearchParams({ page: 'add' })}
        >
          Add New User
        </Button>
      </div>
      {getUsers.isFetching && <Loader height="50px" width="50px" color="black" showTitle />}
      <div ref={divRef as any} style={{ height: Number(availableHeight) - 20 }} className="mt-4">
        <AgGridReact
          enableCellTextSelection
          columnDefs={columnDef}
          rowClass="listing-table-row"
          className="ag-theme-material"
          headerHeight={50}
          rowHeight={50}
          defaultColDef={{ resizable: true, sortable: true }}
          suppressRowTransform
          rowData={users}
        />
      </div>
    </div>
  );
};

export default UserListing;
