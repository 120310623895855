import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import './listing-table.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Button, Tag } from 'antd';
import { PencilIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useAvailableHeight } from 'modules/common/hooks';

const getStatusTagColor = (status: string | undefined) => {
  switch (status) {
    case 'inactive':
      return 'error';

    default:
      return 'success';
  }
};

const ChecklistListingTable = ({ rowData, gridRef }: any) => {
  const navigate = useNavigate();
  const [divRef, availableHeight] = useAvailableHeight();

  const statusCellRenderer = (params: {
    value: string | undefined;
    data: { cancelled_timestamp: string | null | undefined };
  }) => (
    <div>
      <Tag className="capitalize" color={getStatusTagColor(params.value)}>
        {params.value}
      </Tag>
    </div>
  );

  const actionCellRenderer = (params: { data: { checklist_id: number } }) => {
    return (
      <div className="flex gap-2 items-center mt-2">
        <Button
          type="primary"
          className="!bg-colorPrimaryLight !border-colorPrimaryLight hover:opacity-80"
          onClick={() => navigate(`/submissions?id=${params.data.checklist_id}`)}
        >
          View Submissions{' '}
        </Button>
        <Button
          type="text"
          icon={<PencilIcon height={20} width={20} className="float-right" />}
          onClick={() => navigate(`/edit-checklist/${params.data.checklist_id}`)}
        />
      </div>
    );
  };

  const columnDef: ColDef[] = [
    {
      headerName: 'ID',
      hide: true,
      field: 'checklist_id',
      headerClass: 'listing-table-header',
      filter: true,
      sortable: true,
      flex: 0.2,
    },
    {
      headerName: 'CHECKLIST NAME',
      field: 'checklist_name',
      headerClass: 'listing-table-header',
      cellClass: 'capitalize',
      filter: true,
      flex: 0.7,
    },
    {
      headerName: 'CHECKLIST DESCRIPTION',
      field: 'checklist_description',
      headerClass: 'listing-table-header',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      headerClass: 'listing-table-header',
      cellRenderer: statusCellRenderer,
      sortable: true,
      flex: 0.7,
    },
    {
      headerName: '',
      field: 'action',
      headerClass: 'listing-table-header',
      cellRenderer: actionCellRenderer,
      sortable: true,
      flex: 0.7,
    },
  ];

  return (
    <div
      className="h-full mt-4 product-request-table"
      ref={divRef as any}
      style={{ height: Number(availableHeight) - 20 }}
    >
      <AgGridReact
        ref={gridRef}
        enableCellTextSelection
        columnDefs={columnDef}
        rowClass="listing-table-row"
        className="ag-theme-material"
        headerHeight={50}
        rowHeight={50}
        defaultColDef={{ resizable: true, sortable: true }}
        suppressRowTransform
        rowData={rowData}
      />
    </div>
  );
};

export default ChecklistListingTable;
