import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoutes, PublicRestrictedRoutes, PublicRoutes } from '..';
import {
  Login,
  Dashboard,
  ChecklistListing,
  ErrorPage,
  AddChecklist,
  EditChecklist,
  FillChecklist,
  SubmissionsListing,
  SubmissionDetail,
  ScheduleListing,
  CreateSchedule,
  ReportsListing,
  ReportDetail,
  UserManagement,
  FormsListing,
  AddForm,
  EditForm,
  EditSchedule,
} from '../../../modules';
import QCRoutes from '../qc-routes/QC.route';

const RoutesContainer: React.FC = () => {
  return (
    <Routes>
      <Route element={<PublicRestrictedRoutes />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path="/fill-checklist" element={<FillChecklist />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route element={<QCRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/checklists" element={<ChecklistListing />} />
          <Route path="/add-checklist" element={<AddChecklist />} />
          <Route path="/edit-checklist/:id" element={<EditChecklist />} />
          <Route path="/submissions" element={<SubmissionsListing />} />
          <Route path="/submissions/:id" element={<SubmissionDetail />} />
          <Route path="/schedule" element={<ScheduleListing />} />
          <Route path="/create-schedule" element={<CreateSchedule />} />
          <Route path="/edit-schedule/:id" element={<EditSchedule />} />
          <Route path="/reports" element={<ReportsListing />} />
          <Route path="/reports/:id" element={<ReportDetail />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/forms" element={<FormsListing />} />
          <Route path="/forms/add-form" element={<AddForm />} />
          <Route path="/forms/edit-form/:id" element={<EditForm />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default RoutesContainer;
