import api, { TApiCustomResponse, TApiResponse } from '../../../config/api/api.config';

export type TSchedule = {
  schedule_id: number;
  schedule_name: string;
  schedule_description: string;
  checklist_template_id: number;
  checklist_template_name: string;
  week_days: string;
  from_date: string;
  to_date: string | null;
  repeat_every: number;
  repeat_every_uom_id: number;
  all_hours_p: number;
  from_time: string | null;
  to_time: string | null;
  status: string;
  user_id_list: {
    user_id: number;
    first_names_en: string;
    last_name_en: string;
  }[];
  role_id_list: { role_id: number }[];
  facility_assignment_list: [];
  scheduled_at: string;
};

type TScheduleResponse = {
  action: 'search_user_by_filter';
  status: 'successful' | 'failed';
  checklist_schedules: TSchedule[];
};
const editScheduleApi = {
  getSchedule: (scheduleId): TApiCustomResponse<TScheduleResponse> =>
    api.get(`checklist_schedules?schedule_id=${scheduleId}`),
  deleteSchedule: (scheduleId): TApiResponse => api.delete(`checklist_schedule/${scheduleId}`),
  updateSchedule: (payload, scheduleId): TApiResponse => api.put(`checklist_schedule/${scheduleId}`, payload),
};

export default editScheduleApi;
