import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { Button } from 'antd';
import { useAvailableHeight } from 'modules/common/hooks';
import { Link } from 'react-router-dom';

const ReportsListingTable = ({ rowData, gridRef }: any) => {
  const [divRef, availableHeight] = useAvailableHeight();

  const actionCellRenderer = (params: { data: { checklist_id: number } }) => {
    return (
      <Link to={`/reports/${params.data.checklist_id}`}>
        <Button type="primary" className="!bg-colorPrimaryLight !border-colorPrimaryLight hover:opacity-80">
          See Detail{' '}
        </Button>
      </Link>
    );
  };

  function capitalizeColumn(params: any) {
    const { value } = params;
    const firstChar = value.slice(0, 1).toUpperCase();
    return firstChar + value.slice(1);
  }

  const columnDef: ColDef[] = [
    {
      headerName: 'ID',
      hide: true,
      field: 'checklist_id',
      headerClass: 'listing-table-header',
      filter: true,
      sortable: true,
      flex: 0.2,
    },
    {
      headerName: 'CHECKLIST NAME',
      field: 'checklist_name',
      headerClass: 'listing-table-header',
      valueFormatter: capitalizeColumn,
      filter: true,
      flex: 0.7,
    },
    {
      headerName: 'CHECKLIST DESCRIPTION',
      field: 'checklist_description',
      headerClass: 'listing-table-header',
      sortable: true,
      flex: 1,
    },
    {
      headerName: '',
      field: 'action',
      headerClass: 'listing-table-header',
      cellRenderer: actionCellRenderer,
      sortable: true,
      flex: 0.5,
    },
  ];

  return (
    <div
      className="h-full mt-[30px] product-request-table"
      ref={divRef as any}
      style={{ height: Number(availableHeight) - 20 }}
    >
      <AgGridReact
        ref={gridRef}
        enableCellTextSelection
        columnDefs={columnDef}
        rowClass="listing-table-row"
        className="ag-theme-material"
        headerHeight={50}
        rowHeight={50}
        defaultColDef={{ resizable: true, sortable: true }}
        suppressRowTransform
        rowData={rowData}
      />
    </div>
  );
};

export default ReportsListingTable;
