import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import session from '../../config/session/session.config';
import loginApi from './login.api';

import './login.css';

type LoginDto = {
  username: string;
  password: string;
};

type LocationType = {
  state?: {
    from?: {
      pathname?: string;
    };
  };
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loginData, setLoginData] = React.useState<LoginDto>({
    username: '',
    password: '',
  });
  const [invalidCredentials, setInvalidCredentials] = React.useState(false);
  const lastLocation = (location as LocationType)?.state?.from?.pathname || '/';
  const userRef = useRef<HTMLInputElement>(null);

  const loginQuery = useQuery<AxiosResponse>('login', async () => loginApi.login(loginData), {
    enabled: false,
    onSuccess: async (response) => {
      const { headers, data, status } = response;
      if (data.status === 'failed' || status !== 200) {
        return setInvalidCredentials(true);
      }
      const dto = {
        token: headers['x-access-token'],
        id: headers['x-user-id'],
        username: data.username,
      };
      const user = await session.createSession(dto, data);
      if (typeof user !== 'boolean') {
        if (lastLocation === '/') return navigate(user.session.defaultRoute);
        navigate(lastLocation);
      }
      return null;
    },
    onError: () => {
      setInvalidCredentials(true);
      userRef?.current?.focus();
    },
  });

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginQuery.refetch();
  };

  useEffect(() => {
    userRef?.current?.focus();
  }, []);

  return (
    <div className="min-h-screen bg-colorPrimary text-gray-800 antialiased py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:w-96 mx-auto text-center">
        <h1 className="text-white text-7xl">iBlinkX QC</h1>
        <span className="text-2xl text-white font-light">Login to your account</span>
        <div className="mt-4 bg-white shadow-md rounded-lg text-left">
          <div className="h-2 bg-indigo-400 rounded-t-md" />
          <form className="px-8 py-6" onSubmit={(e) => login(e)}>
            <label className="black font-semibold" htmlFor="username-or-email">
              Username or Email
              <input
                id="username-or-email"
                ref={userRef}
                type="text"
                placeholder="Username or Email"
                required
                className="login-input"
                value={loginData.username}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setLoginData({ ...loginData, username: target.value });
                }}
              />
            </label>
            <label className="black font-semibold" htmlFor="password">
              Password
              <input
                id="password"
                type="password"
                placeholder="Password"
                className="login-input"
                required
                value={loginData.password}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  setLoginData({ ...loginData, password: target.value });
                }}
              />
            </label>
            {invalidCredentials && <span className="text-red-600">Invalid Credentials!</span>}
            <div className="flex justify-between items-baseline">
              <button disabled={loginQuery.isFetching} type="submit" className="login-btn">
                {loginQuery.isFetching ? 'Loading' : 'Login'}
              </button>
              <button type="button" className="text-sm hover:underline">
                Forgot Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
