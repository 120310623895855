import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../../modules/common/components';

const QCRoutes = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default QCRoutes;
