import { DatePicker } from 'antd';
import moment from 'moment';
import { FC } from 'react';

type SubmissionFiltersProps = {
  filters: {
    from_timestamp: string | undefined;
    to_timestamp: string | undefined;
  };
  setFilters: (filters: any) => void;
};

const SubmissionFilters: FC<SubmissionFiltersProps> = ({ filters, setFilters }) => {
  const onDateChange = (dates) => {
    setFilters({
      from_timestamp: dates[0].format('YYYY-MM-DD [00:00:00]'),
      to_timestamp: dates[1].format('YYYY-MM-DD [23:59:59]'),
    });
  };

  return (
    <div className="flex items-center gap-2 justify-between">
      <div className="text-2xl font-semibold">Submissions</div>
      <DatePicker.RangePicker
        onChange={onDateChange}
        defaultValue={
          filters.from_timestamp ? [moment(filters.from_timestamp), moment(filters.to_timestamp)] : undefined
        }
      />
    </div>
  );
};

export default SubmissionFilters;
