/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Space, Switch, TimePicker } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import { TSchedule } from 'modules/schedule/edit-schedule/edit-schedule.api';
import { ChecklistPayload } from '../../../../checklists/add-checklist/add-checklist.type';
import createScheduleFormApi from './create-schedule-form.api';

type CreateScheduleFormProps = {
  createSchedule: (values: any, startDate: Date, endDate: Date | null, selectedDays: string) => void;
  scheduleDetails?: TSchedule;
  deleteSchedule?: () => void;
};

const daysArray = [
  {
    id: 0,
    name: 'M',
    selected: false,
  },
  {
    id: 1,
    name: 'T',
    selected: false,
  },
  {
    id: 2,
    name: 'W',
    selected: false,
  },
  {
    id: 3,
    name: 'T',
    selected: false,
  },
  {
    id: 4,
    name: 'F',
    selected: false,
  },
  {
    id: 5,
    name: 'S',
    selected: false,
  },
  {
    id: 6,
    name: 'S',
    selected: false,
  },
];

const allowedMeasurements = ['day', 'week', 'month', 'year'];

type MeasurementProps = {
  measurement_id: number;
  measurement_name: string;
};

const CreateScheduleForm: React.FC<CreateScheduleFormProps> = ({ createSchedule, scheduleDetails, deleteSchedule }) => {
  const [daysList, setDaysList] = useState(daysArray);
  const [checklists, setChecklists] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [facilityUsers, setFacilityUsers] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState<any>();
  const [selectedRepeatOn, setSelectedRepeatOn] = useState('day');
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [isFacility, setIsFacility] = useState(false);
  const [measurementsList, setMeasurementsList] = useState<MeasurementProps[]>([]);
  const [endOn, setEndOn] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().add(1, 'day').toDate());
  const [allDayValue, setAllDayValue] = useState<boolean>(true);
  const [assignUsers, setAssignUsers] = useState<string[]>(['user']);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useQuery('Get Checklists', async () => createScheduleFormApi.getChecklists(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setChecklists(data.result_list);
      }
    },
  });

  useQuery('Get Users list', async () => createScheduleFormApi.getUsersList(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setUsersList(data.result_list.filter((user) => user.role_id !== 1));
      }
    },
  });

  useQuery('Get Roles list', async () => createScheduleFormApi.getRoles(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setRolesList(data.roles.filter((role) => role.role_id !== 1));
      }
    },
  });

  useQuery('Get Facilities list', async () => createScheduleFormApi.getFacilities(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setFacilitiesList(data.facilities);
      }
    },
  });

  useQuery('Get Measurements', async () => createScheduleFormApi.getMeasurements(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setMeasurementsList(
          data.measurements_list.filter((item: any) => allowedMeasurements.includes(item.measurement_name)),
        );
      }
    },
  });

  const facilityUserQuery = useQuery(
    'Get Facility Users',
    async () => createScheduleFormApi.getFacilityUsers(selectedFacilities),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (response) => {
        const { data, status } = response;
        if (status === 200 && data.status === 'successful') {
          setFacilityUsers(data.facility_users_list);
        }
      },
    },
  );

  const confirmDeleteSchedule = () => {
    Modal.confirm({
      title: 'Delete Schedule',
      className: 'checklist-confirm-modal',
      content: 'Are you sure you want to delete this schedule?',
      centered: true,
      cancelButtonProps: {
        className: 'checklist-confirm-modal-cancel-button',
      },
      okButtonProps: { className: 'checklist-confirm-modal-ok-button' },
      width: 480,
      onOk() {
        if (deleteSchedule) deleteSchedule();
      },
    });
  };

  const handleFinish = (values: any) => {
    const updatedValues = { ...values, all_hours_p: values.all_hours_p ? 1 : 0 };
    const updatedEndDate = endOn === 0 ? null : endDate;
    const selectedDays =
      selectedRepeatOn === 'day'
        ? ''
        : daysList
            .filter((day) => day.selected)
            .map((day) => day.id)
            .toString();
    if (!values.all_hours_p) {
      updatedValues.from_time = values.from_time[0].utc().format('HH:mm:ss');
      updatedValues.to_time = values.from_time[1].utc().format('HH:mm:ss');
    }
    if (values.facility) {
      const facilityAssignmentList = [] as any;
      assignUsers.forEach((_item, index) => {
        const newItem = {
          facility_id: values[`checklist_assignments_${index}`].facility_id,
          user_id_list: values[`checklist_assignments_${index}`].user_id_list || [],
          role_id_list: values[`checklist_assignments_${index}`].role_id_list || [],
        };
        facilityAssignmentList.push(newItem);
        delete updatedValues[`checklist_assignments_${index}`];
      });
      updatedValues.checklist_assignments = {
        user_id_list: [],
        role_id_list: [],
        facility_assignment_list: facilityAssignmentList,
      };
    } else {
      const userIds = values.checklist_assignments.filter((item: string) => item.includes('user'));
      const roleIds = values.checklist_assignments.filter((item: string) => item.includes('role'));
      updatedValues.checklist_assignments = {
        user_id_list: userIds.map((userId: string) => Number(userId.replace('user_', ''))),
        role_id_list: roleIds.map((roleId: string) => Number(roleId.replace('role_', ''))),
        facility_assignment_list: [],
      };
    }
    delete updatedValues.facility;
    createSchedule(updatedValues, startDate, updatedEndDate, selectedDays);
  };

  const repeatChange = (_week: number, data: any) => {
    setSelectedRepeatOn(data.children);
  };

  const startOnChange = (time: any) => {
    setStartDate(time);
    setEndDate(moment(time).add(1, 'day').toDate());
  };

  const endDateChange = (time: any) => {
    setEndDate(time);
  };

  const endOnChange = (e: any) => {
    setEndOn(e.target.value);
  };

  const endOnDisabledDate = (current: any) => current && current <= moment(startDate);

  const startOnDisabledDate = (current: any) => current && current < moment().subtract(1, 'days');

  const allDayChange = (value: boolean) => {
    setAllDayValue(value);
  };

  const facilityChange = (value: boolean) => {
    setIsFacility(value);
  };

  const onFacilitySelect = (value: number[]) => {
    setFacilityUsers([]);
    setSelectedFacilities({ facility_id_list: [value] });
  };

  const assignMore = () => {
    setAssignUsers((prev) => prev.concat(`user_${assignUsers.length}`));
  };

  const removeAssignUser = (index: number) => {
    const users = [...assignUsers];
    users.splice(index, 1);
    setAssignUsers(users);
  };

  useEffect(() => {
    form.setFields([
      { name: ['repeat', 'times'], value: 1 },
      { name: ['repeat', 'type'], value: measurementsList[0]?.measurement_id },
    ]);
  }, [measurementsList]);

  useEffect(() => {
    if (selectedFacilities?.facility_id_list) {
      facilityUserQuery.refetch();
    }
  }, [selectedFacilities]);

  useEffect(() => {
    if (scheduleDetails) {
      form.setFieldsValue({
        schedule_name: scheduleDetails.schedule_name,
        schedule_description: scheduleDetails.schedule_description,
        checklist: scheduleDetails.checklist_template_id,
        repeat: {
          times: scheduleDetails.repeat_every,
          type: scheduleDetails.repeat_every_uom_id,
        },
        all_hours_p: !!scheduleDetails.all_hours_p,
        start_on: moment.utc(scheduleDetails.from_date).local(),
        ends: scheduleDetails.to_date ? 1 : 0,
        schedule_status: scheduleDetails.status === 'active',
      });

      if (scheduleDetails.from_time) {
        form.setFieldValue('from_time', [
          moment.utc(scheduleDetails.from_time, 'HH:mm:ss').local(),
          moment.utc(scheduleDetails.to_time, 'HH:mm:ss').local(),
        ]);
      }

      if (scheduleDetails.week_days) {
        const splitWeekDays = scheduleDetails.week_days.split(',');
        setDaysList((prev) =>
          prev.map((item) => (splitWeekDays.includes(String(item.id)) ? { ...item, selected: true } : item)),
        );
      }

      const users = scheduleDetails.user_id_list.map((user) => `user_${user.user_id}`);
      const roles = scheduleDetails.role_id_list.map((role) => `role_${role.role_id}`);
      form.setFieldValue('checklist_assignments', [...users, ...roles]);

      setAllDayValue(!!scheduleDetails.all_hours_p);
      setEndOn(scheduleDetails.to_date ? 1 : 0);
      setSelectedRepeatOn(
        measurementsList.find((measurement) => measurement.measurement_id === scheduleDetails.repeat_every_uom_id)
          ?.measurement_name || 'day',
      );
    }
  }, [scheduleDetails, measurementsList]);

  return (
    <div>
      <div className="flex justify-center flex-col items-center mt-10">
        <div className="bg-white p-10 max-w-4xl w-full rounded">
          <Form
            labelCol={{ span: 6, style: { textAlign: 'left' } }}
            wrapperCol={{ span: 18 }}
            form={form}
            onFinish={handleFinish}
          >
            <Form.Item
              label="Schedule Name"
              name="schedule_name"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter schedule name!' }]}
            >
              <Input className="font-normal" />
            </Form.Item>
            <Form.Item label="Schedule Description" name="schedule_desc" className="font-bold">
              <Input.TextArea rows={4} className="font-normal" />
            </Form.Item>
            <Form.Item
              label="Checklist"
              name="checklist"
              className="font-bold"
              rules={[{ required: true, message: 'Please select checklist!' }]}
            >
              <Select
                showSearch
                className="font-normal"
                filterOption={(input, option: any) =>
                  option ? option?.label?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 : false
                }
                options={checklists.map((checklist: any) => ({
                  label: checklist.checklist_name,
                  value: checklist.checklist_id,
                }))}
              />
            </Form.Item>
            <Form.Item label="Repeat Every" className="font-bold !mb-0" required>
              <Input.Group compact>
                <Form.Item
                  name={['repeat', 'times']}
                  rules={[{ required: true, message: 'Repeat is required' }]}
                  className="w-3/12"
                >
                  <Select defaultValue="1">
                    {[...Array(30)].map((_, i) => (
                      <Select.Option key={i} value={i + 1}>
                        {i + 1}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={['repeat', 'type']}
                  rules={[{ required: true, message: 'Repeat is required' }]}
                  className="w-[70%] !ml-[5%]"
                >
                  <Select onChange={repeatChange} className="capitalize">
                    {measurementsList.map((measurement: any) => (
                      <Select.Option
                        key={measurement.measurement_id}
                        value={measurement.measurement_id}
                        className="capitalize"
                      >
                        {measurement.measurement_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
            {selectedRepeatOn !== 'day' && (
              <Form.Item label="Repeat On" className="font-bold" required>
                <Row>
                  {daysList.map((day, index) => (
                    <Col
                      key={index}
                      onClick={() =>
                        setDaysList((prev) =>
                          prev.map((d, ind) => (ind === index ? { ...d, selected: !d.selected } : d)),
                        )
                      }
                      className={`w-10 h-10 flex justify-center items-center cursor-pointer ${
                        day.selected ? 'bg-colorPrimary text-white' : 'bg-gray-200 text-black'
                      } rounded-full first:ml-0 ml-4`}
                    >
                      {day.name}
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            )}
            <Form.Item
              label={<span className="ml-2">All Day</span>}
              name="all_hours_p"
              className="font-bold"
              valuePropName="checked"
              initialValue
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked onChange={allDayChange} />
            </Form.Item>
            {!allDayValue && (
              <Form.Item
                label="Select Time"
                name="from_time"
                className="font-bold"
                rules={[{ required: true, message: 'Time is required' }]}
              >
                <TimePicker.RangePicker />
              </Form.Item>
            )}
            <Form.Item name="start_on" label="Start On" className="font-bold" required>
              <DatePicker
                onChange={startOnChange}
                value={moment(startDate)}
                allowClear={false}
                disabledDate={startOnDisabledDate}
              />
            </Form.Item>
            <Form.Item name="ends" label="Ends" className="font-bold" required>
              <Radio.Group onChange={endOnChange} defaultValue={0}>
                <Space direction="vertical">
                  <Radio value={0}>NEVER</Radio>
                  <div>
                    <Radio value={1}>ON</Radio>
                    <DatePicker
                      className="!ml-20"
                      onChange={endDateChange}
                      value={moment(endDate)}
                      disabled={endOn === 0}
                      disabledDate={endOnDisabledDate}
                    />
                  </div>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={<span className="ml-2">Facility</span>} name="facility" className="font-bold">
              <Switch checkedChildren="Yes" unCheckedChildren="No" checked={isFacility} onChange={facilityChange} />
            </Form.Item>
            {!isFacility ? (
              <Form.Item
                label="Assign to"
                name="checklist_assignments"
                className="font-bold"
                rules={[{ required: true, message: 'This is required' }]}
              >
                <Select mode="multiple">
                  <Select.OptGroup label="Role">
                    {rolesList.map((role: { role_id: number; role_name: string }) => (
                      <Select.Option key={role.role_id} value={`role_${role.role_id}`}>
                        {role.role_name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                  <Select.OptGroup label="User">
                    {usersList.map((user: { user_id: number; first_names_en: string; last_name_en: string }) => (
                      <Select.Option
                        key={`user_${user.user_id}`}
                        value={`user_${user.user_id}`}
                      >{`${user.first_names_en} ${user.last_name_en}`}</Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </Form.Item>
            ) : (
              <>
                {assignUsers.map((assign: string, index: number) => (
                  <Form.Item key={`${assign}${index}`} label="Assign To" className="font-bold !mb-0" required>
                    <Input.Group compact>
                      <Form.Item
                        name={[`checklist_assignments_${index}`, 'facility_id']}
                        rules={[{ required: true, message: 'Facility is required' }]}
                        className="w-[28%] !mb-0"
                      >
                        <Select placeholder="Select Facility" className="capitalize" onChange={onFacilitySelect}>
                          {facilitiesList.map((facility: { facility_id: number; facility_name: string }) => (
                            <Select.Option
                              key={facility.facility_id}
                              value={facility.facility_id}
                              className="capitalize"
                            >
                              {facility.facility_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[`checklist_assignments_${index}`, 'user_id_list']}
                        rules={[{ required: true, message: 'User is required' }]}
                        className="w-[28%] !ml-[3%] !mb-0"
                      >
                        <Select mode="multiple" placeholder="Select User" maxTagCount="responsive">
                          {facilityUsers.map((user: { user_id: number; full_name: string }) => (
                            <Select.Option key={user.user_id} value={user.user_id}>{`${user.full_name}`}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[`checklist_assignments_${index}`, 'role_id_list']}
                        className="w-[28%] !ml-[3%] !mb-2"
                      >
                        <Select mode="multiple" placeholder="Select Role" maxTagCount="responsive">
                          {rolesList.map((role: { role_id: number; role_name: string }) => (
                            <Select.Option key={role.role_id} value={role.role_id}>
                              {role.role_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {index !== 0 && (
                        <Button
                          type="text"
                          icon={<TrashIcon height={24} width={24} className="text-red-500 ml-2" />}
                          onClick={() => removeAssignUser(index)}
                        />
                      )}
                    </Input.Group>
                  </Form.Item>
                ))}

                <div className="flex justify-end mr-6">
                  <Button type="primary" onClick={assignMore}>
                    Assign More
                  </Button>
                </div>
              </>
            )}
            <Form.Item
              label={<span className="ml-2">Status</span>}
              name="schedule_status"
              className="font-bold"
              valuePropName="checked"
              initialValue
            >
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
            </Form.Item>
          </Form>
        </div>
        <div className="grid grid-cols-2 max-w-4xl w-full mt-4 mb-10">
          <div className="text-left pl-6">
            {scheduleDetails ? (
              <Button type="text" className="!font-bold" onClick={confirmDeleteSchedule}>
                Delete
              </Button>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={() => navigate(`/schedule`)}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
              onClick={() => form.submit()}
            >
              {scheduleDetails ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateScheduleForm;
