import { Breadcrumb, Button, Form, Input, message } from 'antd';
import { Select as CustomSelect, Loader } from 'modules/common/components';
import { TAddUserPayload, TRole, TUpdateUserPayload, TUserDetails } from 'modules/user-management/user-management.type';
import userManagementApi from 'modules/user-management/user-management.api';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

type TFormValues = {
  username: string;
  first_names_en: string;
  last_name_en?: string;
  email: string;
  password: string;
  role_id: number;
};

const UsersUpsert = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState<TUserDetails>();
  const [roles, setRoles] = useState<TRole[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const page = searchParams.get('page');
  const userId = Number(searchParams.get('userId'));
  const [form] = Form.useForm();

  const getUser = useQuery('Get User', async () => userManagementApi.getUser(userId), {
    enabled: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setUserDetails(data.data);
      }
    },
  });

  const fetchRolesQuery = useQuery('getRoles', () => userManagementApi.getRoles(), {
    refetchOnWindowFocus: false,
    onSuccess: ({ data, status }) => {
      if (status === 200 && data.status === 'successful' && data.roles) {
        setRoles(data.roles.filter((role) => role.role_id !== 1));
      }
    },
  });

  const addUser = async (payload: TAddUserPayload) => {
    setIsLoading(true);
    const { data, status } = await userManagementApi.addUser(payload);
    setIsLoading(false);
    if (status === 200 && data.status === 'successful') {
      message.success('User added successfully');
      setSearchParams({ page: 'listing' });
    } else {
      message.error('Something went wrong');
    }
  };

  const updateUser = async (payload: TUpdateUserPayload) => {
    setIsLoading(true);
    const { data, status } = await userManagementApi.updateUser(payload, userId);
    setIsLoading(false);
    if (status === 200 && data.status === 'successful') {
      message.success('User updated successfully');
      setSearchParams({ page: 'details', userId: String(userId) });
    } else {
      message.error('Something went wrong');
    }
  };

  const handleFinish = (values: TFormValues) => {
    const payload = {
      username: values.username,
      email: values.email,
      first_names_en: values.first_names_en,
      last_name_en: values.last_name_en || null,
      password: values.password,
      role_id: values.role_id,
      first_names_ar: null,
      last_name_ar: null,
      phone_nr: null,
      password_expiry_timestamp: null,
      access_token: null,
      station_p: false,
      personal_access_code: null,
      token_expiry_timestamp: null,
      root_p: false,
    };

    if (page === 'add') addUser(payload);
    else if (page === 'edit') updateUser(payload);
  };

  useEffect(() => {
    if (userDetails) {
      form.setFieldsValue({
        username: userDetails.username,
        first_names_en: userDetails.first_names_en,
        last_name_en: userDetails.last_name_en,
        email: userDetails.email,
        role_id: userDetails.role_id,
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (page === 'edit' && userId) {
      getUser.refetch();
    }
    fetchRolesQuery.refetch();
  }, []);

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/user-management" className="!text-[#1890ff]">
            User Management
          </Breadcrumb.Item>
          <Breadcrumb.Item>{page === 'edit' ? 'Update User' : 'Add New'}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="flex justify-center flex-col items-center mt-10">
        {(getUser.isFetching || isLoading) && <Loader height="50px" width="50px" color="black" showTitle />}
        <div className="bg-white p-10 max-w-4xl w-full rounded">
          <Form
            labelCol={{ span: 6, style: { textAlign: 'left' } }}
            wrapperCol={{ span: 14 }}
            form={form}
            onFinish={handleFinish}
          >
            <Form.Item
              label="Username"
              name="username"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter username!' }]}
            >
              <Input className="font-normal" autoComplete="new-username" disabled={page === 'edit'} />
            </Form.Item>
            <Form.Item
              label="First Name"
              name="first_names_en"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter first name!' }]}
            >
              <Input className="font-normal" />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name_en" className="font-bold">
              <Input className="font-normal" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter email!' }]}
            >
              <Input type="email" className="font-normal" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter password!' }]}
            >
              <Input type="password" className="font-normal" autoComplete="new-password" />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role_id"
              className="font-bold"
              rules={[{ required: true, message: 'Please select role!' }]}
            >
              <CustomSelect
                options={roles.map((role) => ({
                  label: role.role_name,
                  value: role.role_id,
                  description: role.role_description,
                }))}
                onChange={(value) => form.setFieldValue('role_id', value)}
                className="w-72"
                placeholder="Select Role"
                optionClassName="font-normal"
                dropdownClassName="w-72"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="grid grid-cols-2 max-w-4xl w-full mt-4">
          <div className="text-left pl-6">
            <Button type="text" className="!font-bold" hidden>
              Delete
            </Button>
          </div>
          <div className="flex justify-end">
            <Button
              type="default"
              className="w-28 mr-2 !border-0 !font-bold"
              onClick={() => setSearchParams({ page: 'listing' })}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
              onClick={() => form.submit()}
            >
              {page === 'edit' ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersUpsert;
