/* eslint-disable react/prop-types */
import { useRef, useState } from 'react';
import { Breadcrumb, Progress, Space } from 'antd';
import { ResponsiveContainer, Tooltip, Cell, PieChart, Pie } from 'recharts';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import reportDetailApi, { TCheckPool } from './report-detail.api';
import { Loader } from '../../common/components';
import ImagePreviewModal, {
  ImagePreviewHandle,
} from '../../checklists/fill-checklist/components/image-preview-modal/ImagePreviewModal.component';

type ChecklistProps = {
  checklist_name: string;
  checklist_description: string;
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const ReportDetail = () => {
  const [checklistDetail, setChecklistDetail] = useState<ChecklistProps>();
  const [checkPools, setCheckPools] = useState<TCheckPool[]>([]);

  const imagePreviewModalRef = useRef<ImagePreviewHandle>();
  const { id = '' } = useParams();

  const getChecklistDetails = useQuery('Get Checklist Pools', async () => reportDetailApi.getChecklistPool(id), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data: checklistData, status } = response;
      if (status === 200 && checklistData.status === 'successful') {
        setChecklistDetail(checklistData);
        setCheckPools(
          checklistData.checks_score_list.map((check: any) => {
            const totalOfChoice = check.choices?.reduce((acc, obj) => {
              return acc + Number(obj.answered_count || 0);
            }, 0);
            return {
              ...check,
              scores: check.scores?.map((score: any) => ({
                name: score.answer_text,
                value: score.answered_count,
              })),
              choices: check.choices?.map((choice: any, index: number) => {
                const choicePercentage = (choice.answered_count / totalOfChoice) * 100;
                return {
                  name: choice.choice_type === 'image' ? `Choice ${index + 1}` : choice.choice_text,
                  value: choice.answered_count,
                  type: choice.choice_type,
                  media_url: choice.media_url || '',
                  percentage: choicePercentage > 0 ? Number(choicePercentage.toFixed(2)) : 0,
                };
              }),
            };
          }),
        );
      }
    },
  });

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <ImagePreviewModal ref={imagePreviewModalRef} />
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/reports" className="!text-[#1890ff]">
            Reports
          </Breadcrumb.Item>
          <Breadcrumb.Item>Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white mx-52 mt-5 px-10 py-5 rounded shadow-md">
        {getChecklistDetails.isLoading && <Loader height="50px" width="50px" color="black" showTitle />}
        <div className="text-2xl font-bold capitalize">{checklistDetail?.checklist_name}</div>
        <div className="text-md first-letter:capitalize">{checklistDetail?.checklist_description}</div>
        <hr className="my-2 -mx-2" />
        {getChecklistDetails.isFetched &&
          checkPools.map((check, checkIndex) => (
            <div key={check.check_id} className="mt-8">
              <div className="text-lg font-semibold mb-2 capitalize">{`${checkIndex + 1}. ${check.check_text}`}</div>
              {(check.check_type === 'image' || check.check_type === 'text-image') &&
                check.media?.map((item: any, index: number) => (
                  <Space key={item.check_media_id} direction="horizontal" className="ml-5">
                    <button
                      type="button"
                      onClick={() => {
                        if (imagePreviewModalRef.current) imagePreviewModalRef.current.toggleModal(item.url || '');
                      }}
                    >
                      <img
                        src={item.url}
                        alt="Check"
                        className={`h-32 w-32 rounded ${index === 0 ? 'ml-0' : 'ml-4'} mb-4`}
                      />
                    </button>
                  </Space>
                ))}
              {check.answer_type === 'single-selection' || check.answer_type === 'multiple-selection' ? (
                <div className="ml-5">
                  <div className="w-full flex">
                    {check.choices.map((choice: any) =>
                      choice.type === 'image' ? (
                        <div className="mb-4">
                          <button
                            type="button"
                            onClick={() => {
                              if (imagePreviewModalRef.current)
                                imagePreviewModalRef.current.toggleModal(choice.media_url || '');
                            }}
                          >
                            <img src={choice.media_url} className="h-20 w-20" alt="" />
                          </button>
                          <div className="text-center text-xs">{choice.name}</div>
                        </div>
                      ) : (
                        <div />
                      ),
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    {check.choices.map((choice) => (
                      <div key={choice.choice_id} className="flex gap-2 items-start">
                        <div>
                          <div className="w-1.5 h-1.5 bg-black rounded-full mt-2" />
                        </div>
                        <div className="grid grid-cols-5 gap-4 w-full">
                          <div>{choice.name}</div>
                          <div className="col-span-4 flex gap-4">
                            <div className="w-[80%]">
                              <Progress
                                percent={choice.percentage}
                                strokeLinecap="butt"
                                strokeWidth={20}
                                showInfo={false}
                              />
                            </div>
                            <div className="flex">{`${choice.percentage}% (${choice.value})`}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="ml-5">
                  <div className="font-semibold">Top 3 Answers</div>
                  <ResponsiveContainer width="100%" height={230} debounce={50}>
                    <PieChart width={400} height={200}>
                      <Tooltip />
                      <Pie
                        data={check.scores}
                        cx={150}
                        labelLine={false}
                        label
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {check.scores.map((d: any, index: number) => (
                          <Cell key={d.answered_count} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="h-10" />
    </div>
  );
};

export default ReportDetail;
