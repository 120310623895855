import { TCurrentUser } from 'modules/common/types/common.type';
import sharedApi from 'modules/common/shared.api';
import { Modal } from 'antd';
import routes from './route-roles.json';
// eslint-disable-next-line import/no-cycle
import api from '../api/api.config';

const rolesAllowed = [1, 4];

export type UserSession = {
  username: string;
  userId: number;
  userRole: string;
  roleId: number;
  defaultRoute: string;
};

export type SessionData = {
  id: string;
  token: string;
  username: string;
  expiry?: number;
};

type Route = {
  path: string;
  roles: number[];
};

let refreshTimeOut: NodeJS.Timeout;

const session = {
  /**
   * Creates in memory session using local storage
   * TODO: If expiry is provided refresh session 60 seconds before expiring (Depending upon backend implementation)
   * @param  { id: xUserId, token: decoded_access_token } data
   * returns user
   */
  createSession: (data: SessionData, userData: UserSession): Promise<TCurrentUser | boolean> => {
    if (data.expiry) {
      const nextRefresh = (data.expiry - 60) * 1000;
      refreshTimeOut = setTimeout(async () => {
        // const response = await sharedApi.refreshSession();
        // if (response.status === 201) session.createSession(response.data);
      }, nextRefresh);
    }
    localStorage.setItem('logIn', new Date().toString());
    localStorage.setItem('sessionToken', JSON.stringify(data));
    // api.setAuthenticationHeader(data);
    return session.verifyAndUpdateUser(userData, data);
  },
  /**
   * Gets current user session from local storage
   * returns user object if user exists else returns undefined
   */
  getCurrentSession: (): TCurrentUser | undefined => {
    const user = localStorage.getItem('currentUser');
    if (user) return JSON.parse(user) as TCurrentUser;
    return undefined;
  },
  /**
   * Checks if user is logged in or not
   * @returns UserSession | undefined
   */
  isLoggedIn: (): TCurrentUser | undefined => {
    const user = localStorage.getItem('currentUser');
    const token = localStorage.getItem('sessionToken');
    if (user && token) return JSON.parse(user);
    return undefined;
  },
  /**
   * Destroys current session preserved in local storage
   */
  destroySession: async () => {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('currentUser');
    localStorage.setItem('logOut', new Date().toString());
    // api.removeAuthenticationHeader();
    const currentUserUpdated = new CustomEvent('currentUserUpdated', { detail: undefined });
    window.dispatchEvent(currentUserUpdated);
    clearTimeout(refreshTimeOut);
  },
  /**
   * Refreshes current session using refresh api
   */
  refreshSession: () => {
    // TODO: Implement refresh session depending upon backend implementation
  },
  /**
   * Authenticate if current path is allowed for current user role
   * @param path - string
   * returns true or alternate path
   */
  authenticateRoute: (path: string): boolean | string => {
    const user = localStorage.getItem('currentUser');
    if (user) {
      const currentUser = JSON.parse(user) as TCurrentUser;
      const currentRoute = routes.find((route) => route.path === path) as Route;
      if (currentRoute) {
        return currentRoute.roles.includes(currentUser.user_details.role_id) ? true : currentUser.session.defaultRoute;
      }
      return true;
    }
    return '/';
  },
  /**
   * Verifies current session by confirming if session token exists in local storage
   * Calls verifyAndUpdateUser to verify session
   * returns user
   */
  verifySession: (): Promise<TCurrentUser | boolean> => {
    const token = localStorage.getItem('sessionToken');
    const user = localStorage.getItem('currentUser');
    if (token && user) {
      api.setAuthenticationHeader(JSON.parse(token));
      return JSON.parse(user);
      // return session.verifyAndUpdateUser();
    }
    session.destroySession();
    return Promise.resolve(false);
  },
  /**
   * Verifies if current session is still valid
   * Calls getCurrentUser to verify if current session is valid
   * If backend does not return 200 status destroys current session from local storage
   * returns user
   */
  verifyAndUpdateUser: async (userData: UserSession, data: SessionData): Promise<TCurrentUser | boolean> => {
    const { data: user, status } = await sharedApi.getCurrentUser(
      userData
        ? {
            'x-user-id': data.id || '',
            'x-access-token': data.token || '',
            'x-username': userData.username || '',
          }
        : undefined,
    );
    if (status !== 200) {
      session.destroySession();
      return Promise.resolve(false);
    }

    if (rolesAllowed.includes(user.user_details.role_id)) {
      const updatedData = { ...user, session: { ...userData, defaultRoute: '/dashboard' } };
      localStorage.setItem('currentUser', JSON.stringify(updatedData));
      const currentUserUpdated = new CustomEvent('currentUserUpdated', { detail: updatedData });
      window.dispatchEvent(currentUserUpdated);
      return updatedData;
    }

    Modal.warning({
      title: 'Authorization Denied',
      closable: true,
      centered: true,
      content: 'You are not authorized to access the QC web portal',
    });

    return Promise.resolve(false);
  },
};

export default session;
