/* eslint-disable react/no-array-index-key */
import { Checkbox, Radio, Space } from 'antd';
import React, { useRef } from 'react';
import ImagePreviewModal, {
  ImagePreviewHandle,
} from '../../../../checklists/fill-checklist/components/image-preview-modal/ImagePreviewModal.component';
import './submission-answers.css';

type ChecklistAnswerProps = {
  answerType: string;
  checkDetails: any;
};

const SubmissionAnswers: React.FC<ChecklistAnswerProps> = ({ answerType, checkDetails }) => {
  const imagePreviewModalRef = useRef<ImagePreviewHandle>();

  const getAnswerComponent = () => {
    switch (answerType) {
      case 'text':
        return <div className="text-white bg-colorPrimaryLight/90 p-2 rounded">{checkDetails.answer_text} </div>;

      case 'date-range':
        return (
          <div className="text-white bg-colorPrimaryLight/90 p-2 rounded">
            <span>From: </span>
            <span className="font-bold">{checkDetails?.answer_date_range?.from_date}</span>
            <span className="ml-8"> To: </span>
            <span className="font-bold">{checkDetails?.answer_date_range?.to_date}</span>
          </div>
        );

      case 'single-image-open':
        return (
          <button
            type="button"
            onClick={() => {
              if (imagePreviewModalRef.current)
                imagePreviewModalRef.current.toggleModal(checkDetails.answer_media_url || '');
            }}
            className="mt-4"
          >
            <img src={checkDetails.answer_media_url} alt="avatar" className="h-52" />
          </button>
        );

      case 'multiple-image-open':
        return checkDetails.answer_media_url_list.map((media: any, index: number) => (
          <button
            key={index}
            type="button"
            className="mt-4"
            onClick={() => {
              if (imagePreviewModalRef.current) imagePreviewModalRef.current.toggleModal(media || '');
            }}
          >
            <img src={media} alt="avatar" className="h-52" />
          </button>
        ));

      case 'single-selection':
        return (
          <div className="grid grid-cols-4">
            <Radio.Group
              name="radiogroup"
              className="checklist-answer-radio col-span-3"
              value={checkDetails.answer_choice_id ? checkDetails.answer_choice_id[0] : null}
            >
              <Space direction="horizontal">
                {checkDetails.choices?.map((choice: any) => {
                  const isAnswerNo =
                    choice.choice_id === checkDetails.answer_choice_id[0] && choice.choice_text?.toLowerCase() === 'no';
                  const isAnswerYes =
                    choice.choice_id === checkDetails.answer_choice_id[0] &&
                    choice.choice_text?.toLowerCase() === 'yes';
                  return (
                    <Radio
                      key={choice.choice_id}
                      className={`!mt-2 ${isAnswerNo && 'radio-red'}`}
                      value={choice.choice_id}
                    >
                      {choice.choice_type === 'image' ? (
                        <button
                          type="button"
                          onClick={() => {
                            if (imagePreviewModalRef.current)
                              imagePreviewModalRef.current.toggleModal(choice.media_url || '');
                          }}
                        >
                          <img src={choice.media_url} className="w-20 h-20 rounded" alt="Choice" />
                        </button>
                      ) : (
                        <span
                          className={`capitalize ${isAnswerNo && 'text-red-500'} ${isAnswerYes && 'text-[#1890ff]'}`}
                        >
                          {choice.choice_text}
                        </span>
                      )}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
            {/* <div className="flex justify-center mt-2 font-semibold">
              {checkDetails.choices
                .find((choice: any) => choice.choice_id === checkDetails.answer_choice_id[0])
                .choice_text?.toLowerCase() === 'yes' ? (
                <span className="text-[#1890ff]">(1/1)</span>
              ) : (
                <span className="text-red-500">(0/1)</span>
              )}
            </div> */}
          </div>
        );

      case 'multiple-selection':
        return (
          <Checkbox.Group name="radiogroup" className="checklist-answer-radio" value={checkDetails.answer_choice_id}>
            <div className="grid grid-cols-4 gap-4">
              {checkDetails.choices?.map((choice: any) => (
                <Checkbox key={choice.choice_id} value={choice.choice_id} className="!mt-2">
                  {choice.choice_type === 'image' ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (imagePreviewModalRef.current)
                          imagePreviewModalRef.current.toggleModal(choice.media_url || '');
                      }}
                    >
                      <img src={choice.media_url} className="w-20 h-20 rounded" alt="Choice" />
                    </button>
                  ) : (
                    <span className="capitalize">{choice.choice_text}</span>
                  )}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        );

      default:
        return <div />;
    }
  };
  return (
    <div>
      <ImagePreviewModal ref={imagePreviewModalRef} />
      {getAnswerComponent()}
    </div>
  );
};

export default SubmissionAnswers;
