import React, { useRef, useState } from 'react';
import { Breadcrumb, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import checklistApi from './forms-listing.api';
import { Loader } from '../../common/components';
import { FormsListingTable } from './components';

const FormsListing = () => {
  const [checklists, setChecklists] = useState([]);
  const gridRef = useRef();
  const navigate = useNavigate();

  const getChecklists = useQuery('Get Checklists', async () => checklistApi.getChecklist(), {
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setChecklists(data.result_list);
      }
    },
  });

  return (
    <div className="px-[20px] py-[10px] h-[90%]">
      <div className="bg-gray-200 p-[10px] mb-4 rounded">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard" className="!text-[#1890ff]">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>Forms</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="text-right">
        <Button
          className="!bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !rounded-md !px-8"
          onClick={() => navigate(`add-form`)}
        >
          Create New Form
        </Button>
      </div>
      {getChecklists.isFetching && <Loader height="50px" width="50px" color="black" showTitle />}
      {getChecklists.isFetched && <FormsListingTable gridRef={gridRef} rowData={checklists} />}
    </div>
  );
};

export default FormsListing;
