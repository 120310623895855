import { Checkbox, Form, Input, message, Radio, Space, Upload } from 'antd';
import React, { useRef, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';
import { getBase64 } from '../../../add-checklist/components/checklist-detail/components/select-checklist/components/photo-check/PhotoCheck.component';
import './checklist-answer.css';
import ImagePreviewModal, { ImagePreviewHandle } from '../image-preview-modal/ImagePreviewModal.component';
import { CheckArrayContent } from '../../../add-checklist/components/checklist-detail/checklist-details.type';

type ChecklistAnswerProps = {
  answerType: string;
  checkDetails: CheckArrayContent;
};

const uploadButton = (
  <div>
    <PlusIcon height={20} width={20} className="m-auto" />
    <div style={{ marginTop: 8 }}>Select Image</div>
  </div>
);

const ChecklistAnswer: React.FC<ChecklistAnswerProps> = ({ answerType, checkDetails }) => {
  const [imageUrl, setImageUrl] = useState('');
  const imagePreviewModalRef = useRef<ImagePreviewHandle>();
  const [multipleImagesUrl, setMultipleImagesUrl] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    if (file.status !== 'removed') {
      const isLt2M = (file?.size || 0) / 1024 / 1024 < 2;
      if (isLt2M) {
        getBase64(file as RcFile, (url) => {
          // setLoading(false);
          setImageUrl(url);
        });
      } else {
        message.error('Image must smaller than 2MB!');
      }
    }
  };

  const handleMultipleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    if (file.status !== 'removed') {
      const isLt2M = (file?.size || 0) / 1024 / 1024 < 2;
      if (isLt2M) {
        setSelectedFiles(info.fileList);
        getBase64(file as RcFile, (url) => {
          // setLoading(false);
          setMultipleImagesUrl([...multipleImagesUrl, url]);
        });
      } else {
        message.error('Image must smaller than 2MB!');
      }
    }
  };

  const handleRemove = (file: any) => {
    setSelectedFiles(selectedFiles.filter((f) => f.uid !== file.uid));
    setMultipleImagesUrl(multipleImagesUrl.filter((image: string) => image !== file.url));
    return true;
  };

  const getAnswerComponent = () => {
    switch (answerType) {
      case 'text':
        return <Input placeholder="Your Answer" />;

      case 'single-image-open':
        return (
          <Upload
            name="picture"
            listType="picture-card"
            accept="image/png, image/jpeg"
            className="avatar-uploader"
            multiple={false}
            beforeUpload={() => false}
            showUploadList={false}
            onChange={handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        );

      case 'multiple-image-open':
        return (
          <Upload
            name="picture"
            fileList={selectedFiles}
            listType="picture-card"
            accept="image/png, image/jpeg"
            className="avatar-uploader"
            beforeUpload={() => false}
            onPreview={() => {
              return false;
            }}
            onChange={handleMultipleChange}
            onRemove={handleRemove}
          >
            {selectedFiles.length >= 8 ? null : uploadButton}
          </Upload>
        );

      case 'single-selection':
        return (
          <Radio.Group name="radiogroup" className="checklist-answer-radio">
            <Space direction="horizontal">
              {checkDetails.choices?.map((choice) => (
                <Radio key={choice.choice_id} value={choice.choice_id} className="!mt-2">
                  {choice.choice_type === 'image' ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (imagePreviewModalRef.current)
                          imagePreviewModalRef.current.toggleModal(choice.media_url || '');
                      }}
                    >
                      <img src={choice.media_url} className="w-20 h-20 rounded" alt="Choice" />
                    </button>
                  ) : (
                    <span className="capitalize">{choice.choice_text}</span>
                  )}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        );

      case 'multiple-selection':
        return (
          <Checkbox.Group name="radiogroup" className="checklist-answer-radio">
            <Space direction="horizontal">
              {checkDetails.choices?.map((choice) => (
                <Checkbox key={choice.choice_id} value={choice.choice_id} className="!mt-2">
                  {choice.choice_type === 'image' ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (imagePreviewModalRef.current)
                          imagePreviewModalRef.current.toggleModal(choice.media_url || '');
                      }}
                    >
                      <img src={choice.media_url} className="w-20 h-20 rounded" alt="Choice" />
                    </button>
                  ) : (
                    <span className="capitalize">{choice.choice_text}</span>
                  )}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        );

      default:
        return <div />;
    }
  };
  return (
    <div>
      <ImagePreviewModal ref={imagePreviewModalRef} />
      <Form.Item
        name={`check_${checkDetails.check_id}`}
        rules={[
          {
            required: !!(checkDetails.required_p === undefined || checkDetails.required_p === 1),
            message: 'This check is required!',
          },
        ]}
      >
        {getAnswerComponent()}
      </Form.Item>
      {(checkDetails.answer_type.includes('selection') || checkDetails.answer_type.includes('image')) && (
        <Form.Item name={`comment_${checkDetails.check_id}`} className="!mt-4">
          <Input.TextArea placeholder="Comments" rows={2} />
        </Form.Item>
      )}
    </div>
  );
};

export default ChecklistAnswer;
