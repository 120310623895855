/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import checklistInfoApi from './form-Info.api';

export type ChecklistDetailData = {
  checklist_id?: number;
  checklist_name: string;
  checklist_desc: string;
  checklist_status: boolean | undefined;
  checklist_type_id?: number;
  checklist_point_id?: number[];
  approval_required_p: number | boolean;
  all_users_mandatory_p: number | boolean;
  approval_assignments?: string[];
  user_id_list?: number[];
  role_id_list?: number[];
  user_role_list?: {
    user_id: number | null;
    role_id: number | null;
  }[];
};

type FormInfoProps = {
  checklistDetails: ChecklistDetailData | undefined;
  setChecklistDetails: (checklistDetail: ChecklistDetailData) => void;
  setShowCheckTypes: (showCheckType: boolean) => void;
  deleteChecklist?: () => void;
};

const FormInfo: React.FC<FormInfoProps> = ({
  checklistDetails,
  setChecklistDetails,
  setShowCheckTypes,
  deleteChecklist,
}) => {
  const [checklistPoints, setChecklistPoints] = useState([]);
  const [approvalRequired, setApprovalRequired] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const getChecklistPoints = useQuery('Get Checklist Points', async () => checklistInfoApi.getChecklistPoints(), {
    enabled: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setChecklistPoints(data.checklist_points);
      }
    },
  });

  useQuery('Get Users list', async () => checklistInfoApi.getUsersList(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setUsersList(data.result_list.filter((user) => user.role_id !== 1));
      }
    },
  });

  useQuery('Get Roles list', async () => checklistInfoApi.getRoles(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setRolesList(data.roles.filter((role) => role.role_id !== 1));
      }
    },
  });

  const handleFinish = (values: ChecklistDetailData) => {
    const userIds = values.approval_assignments?.filter((item: string) => item.includes('user'));
    const roleIds = values.approval_assignments?.filter((item: string) => item.includes('role'));

    setChecklistDetails({
      ...values,
      checklist_id: checklistDetails?.checklist_id,
      approval_required_p: values.approval_required_p ? 1 : 0,
      all_users_mandatory_p: values.all_users_mandatory_p ? 1 : 0,
      user_id_list: userIds?.map((userId: string) => Number(userId.replace('user_', ''))),
      role_id_list: roleIds?.map((roleId: string) => Number(roleId.replace('role_', ''))),
    });

    setShowCheckTypes(true);
  };

  const confirmDeleteChecklist = () => {
    Modal.confirm({
      title: 'Delete Form',
      className: 'checklist-confirm-modal',
      content: 'Are you sure you want to delete this form?',
      centered: true,
      cancelButtonProps: {
        className: 'checklist-confirm-modal-cancel-button',
      },
      okButtonProps: { className: 'checklist-confirm-modal-ok-button' },
      width: 480,
      onOk() {
        if (deleteChecklist) deleteChecklist();
      },
    });
  };

  useEffect(() => {
    getChecklistPoints.refetch();
  }, []);

  useEffect(() => {
    if (checklistDetails) {
      form.setFieldValue('checklist_name', checklistDetails.checklist_name);
      form.setFieldValue('checklist_desc', checklistDetails.checklist_desc);
      form.setFieldValue('checklist_point_id', checklistDetails.checklist_point_id);
      form.setFieldValue('checklist_status', checklistDetails.checklist_status);
      form.setFieldValue('approval_required_p', !!checklistDetails.approval_required_p);
      form.setFieldValue(
        'approval_assignments',
        checklistDetails?.user_role_list?.map((item) =>
          item.user_id ? `user_${item.user_id}` : `role_${item.role_id}`,
        ),
      );
      form.setFieldValue('all_users_mandatory_p', !!checklistDetails.all_users_mandatory_p);

      setApprovalRequired(!!checklistDetails.all_users_mandatory_p);
    }
  }, [checklistDetails]);

  return (
    <div>
      <div className="flex justify-center flex-col items-center mt-10">
        <div className="bg-white p-10 max-w-4xl w-full rounded">
          <Form
            labelCol={{ span: 6, style: { textAlign: 'left' } }}
            wrapperCol={{ span: 14 }}
            form={form}
            onFinish={handleFinish}
          >
            <Form.Item
              label="Form Name"
              name="checklist_name"
              className="font-bold"
              rules={[{ required: true, message: 'Please enter form name!' }]}
            >
              <Input className="font-normal" />
            </Form.Item>
            <Form.Item label="Form Description" name="checklist_desc" className="font-bold">
              <Input.TextArea rows={4} className="font-normal" />
            </Form.Item>
            <Form.Item label="Form Point" name="checklist_point_id" className="font-bold">
              <Select mode="multiple" allowClear className="capitalize">
                {checklistPoints.map((point: { checklist_point_id: number; checklist_point_name: string }) => (
                  <Select.Option key={point.checklist_point_id} value={point.checklist_point_id} className="capitalize">
                    {point.checklist_point_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Approval Required"
              name="approval_required_p"
              className="font-bold"
              valuePropName="checked"
              initialValue={false}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(e) => setApprovalRequired(e)} />
            </Form.Item>
            {approvalRequired && (
              <>
                <Form.Item
                  label="Select Users or Roles"
                  name="approval_assignments"
                  className="font-bold"
                  rules={[{ required: true, message: 'This is required' }]}
                >
                  <Select mode="multiple">
                    <Select.OptGroup label="Role">
                      {rolesList.map((role: { role_id: number; role_name: string }) => (
                        <Select.Option key={role.role_id} value={`role_${role.role_id}`}>
                          {role.role_name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label="User">
                      {usersList.map((user: { user_id: number; first_names_en: string; last_name_en: string }) => (
                        <Select.Option
                          key={`user_${user.user_id}`}
                          value={`user_${user.user_id}`}
                        >{`${user.first_names_en} ${user.last_name_en}`}</Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="All Approvals Mandatory"
                  name="all_users_mandatory_p"
                  className="font-bold"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Status"
              name="checklist_status"
              className="font-bold"
              valuePropName="checked"
              initialValue
            >
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
            </Form.Item>
          </Form>
        </div>
        <div className="grid grid-cols-2 max-w-4xl w-full mt-4">
          <div className="text-left pl-6">
            {checklistDetails ? (
              <Button type="text" className="!font-bold" onClick={confirmDeleteChecklist}>
                Delete
              </Button>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button type="default" className="w-28 mr-2 !border-0 !font-bold" onClick={() => navigate(`/forms`)}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="w-28 !bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !border-0 !font-bold"
              onClick={() => form.submit()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormInfo;
