import { useEffect, useRef, useState } from 'react';

const useAvailableHeight = () => {
  const divRef = useRef<HTMLElement>(null);
  const [availableHeight, setAvailableHeight] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setAvailableHeight(window.innerHeight - rect.top);
    }
  }, []);

  return [divRef, availableHeight];
};

export default useAvailableHeight;
