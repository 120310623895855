import api from '../../../config/api/api.config';

const submissionListingApi = {
  getChecklists: (params, checklistTemplateId) => {
    const updatedParams = {} as any;
    if (params.from_timestamp) {
      updatedParams.from_timestamp = params.from_timestamp;
      updatedParams.to_timestamp = params.to_timestamp;
    }
    if (checklistTemplateId) {
      updatedParams.checklist_template_id = checklistTemplateId;
    }
    return api.get(`submitted_checklists`, { params: updatedParams });
  },
};

export default submissionListingApi;
