import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { Loader } from 'modules/common/components';
import { useNavigate } from 'react-router-dom';
import dashboardApi, { TFulfillmentCount } from './dashboard.api';

const Dashboard = () => {
  const [fulfillmentCount, setFulfillmentCount] = useState<TFulfillmentCount>();
  const navigate = useNavigate();

  const getFulfillmentCount = useQuery('Get Fulfillment Count', async () => dashboardApi.getFulfillmentCount(), {
    enabled: false,
    onSuccess: (response) => {
      const { data, status } = response;
      if (status === 200 && data.status === 'successful') {
        setFulfillmentCount(data.data);
      }
    },
  });

  useEffect(() => {
    getFulfillmentCount.refetch();
  }, []);

  return (
    <div className="h-screen p-4">
      {getFulfillmentCount.isFetching && <Loader height="50px" width="50px" color="black" showTitle />}
      {/* <DashboardFilters filters={filters} setFilters={setFilters} /> */}
      <div className="text-2xl font-semibold">Dashboard</div>
      <div className="relative grid grid-cols-3 gap-2 mt-4">
        <div className="w-auto rounded-md border-[0.5px] border-black/10 bg-white px-5 py-3 text-left shadow-sm">
          <div className="text-sm font-semibold">Total Active Checklists</div>
          <div className="text-3xl font-bold">{Number(fulfillmentCount?.checklist_template_count || 0)}</div>
        </div>
        <div className="w-auto rounded-md border-[0.5px] border-black/10 bg-white px-5 py-3 text-left shadow-sm">
          <div className="text-sm font-semibold">Total Assigned Checklists</div>
          <div className="text-3xl font-bold">{Number(fulfillmentCount?.assigned_checklist_template_count || 0)}</div>
        </div>
        <div className="w-auto rounded-md border-[0.5px] border-black/10 bg-white px-5 py-3 text-left shadow-sm">
          <div className="text-sm font-semibold">Past Due Date (unsubmitted)</div>
          <div className="text-3xl font-bold text-red-600">{Number(fulfillmentCount?.overdue_count || 0)}</div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-2 px-4 py-2 bg-white rounded mb-10">
        <ul className="divide-y divide-gray-100 mb-0">
          {fulfillmentCount?.fulfillment_count_list.reverse().map((item) => (
            <li key={item.checklist_template_id} className="flex items-center justify-between gap-x-6 py-2">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900 capitalize">
                    Checklist: {item.checklist_template_name}
                  </p>
                </div>
                <div className="flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="whitespace-nowrap">Assigned: {item.assigned_count}</p>
                  <p className="truncate">Completed: {item.fulfillment_count}</p>
                  <p className="truncate">Due: {item.due_count}</p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  type="button"
                  onClick={() => navigate(`/reports/${item.checklist_template_id}`)}
                  className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                >
                  View Results
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/submissions?id=${item.checklist_template_id}`)}
                  className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                >
                  View Submissions
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
