import api, { TApiCustomResponse, TApiResponse } from 'config/api/api.config';
import { TAddUserPayload, TRole, TUpdateUserPayload, TUser, TUserDetails } from './user-management.type';

type TUsersResponse = {
  action: 'search_user_by_filter';
  status: 'successful' | 'failed';
  result_list: TUser[];
};

type TRolesResponse = {
  action: 'search_user_by_filter';
  status: 'successful' | 'failed';
  roles: TRole[];
};

const userManagementApi = {
  getUsers: (): TApiCustomResponse<TUsersResponse> => api.get(`users`),
  getUser: (userId): TApiResponse<TUserDetails> => api.get(`user/${userId}`),
  addUser: (payload: TAddUserPayload): TApiResponse => api.post('user', payload),
  deleteUser: (userId): TApiResponse => api.delete(`user/${userId}`),
  updateUser: (payload: TUpdateUserPayload, userId): TApiResponse => api.put(`user/${userId}`, payload),
  getRoles: (): TApiCustomResponse<TRolesResponse> => api.get('roles'),
};

export default userManagementApi;
