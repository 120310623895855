import React from 'react';
import './loader.css';

type Props = {
  color: string;
  height: string;
  width: string;
  title?: string;
  showTitle?: boolean;
};

const Loader = ({ color = 'black', height = '75px', width = '75px', title, showTitle }: Props) => (
  <div className="overlay">
    <div className="overlay__inner">
      <div className="overlay__content">
        <span className="spinner" style={{ borderTopColor: color, height, width }} />
      </div>
      <div className="overlay__content">
        <span className="loader_title" style={{ display: showTitle ? 'block' : 'none' }}>
          {title}
        </span>
      </div>
    </div>
  </div>
);

Loader.defaultProps = {
  title: 'Loading...',
  showTitle: false,
};

export default Loader;
